export const simulatedStory = [
    {
        "chapter": 1,
        "title": "Chapter 1: The Journey Begins",
        "text": "It was a typical Saturday morning when Ryan, Leah, Samantha, Connor, and Marie piled into their car for what they thought would be a fun day at the park. As they drove through the countryside, the sky suddenly darkened, and a strange, pulsating light appeared on the horizon. Curious and a little uneasy, Ryan decided to drive toward the light, thinking it was some kind of meteorological phenomenon. But as they approached, their car was engulfed in a blinding flash, and the landscape around them began to warp and shift.\n\nWhen the light finally faded, the family found themselves in an unfamiliar world, the sky a deep shade of purple and the ground covered in metallic, alien plants. Their car was gone, replaced by a sleek, hovering vehicle that seemed to respond to their thoughts. Leah, ever the practical one, quickly took stock of their situation. They were no longer on Earth, but in some far-off corner of the galaxy.\n\n\"This is not a drill,\" Ryan said, trying to keep his voice calm for the kids. Samantha, Connor, and Marie exchanged nervous glances as the reality of their situation sank in. Before they could discuss what to do next, the hovering vehicle began to move on its own, speeding towards a distant structure that loomed on the horizon. The journey had begun, and there was no turning back."
    },
    {
        "chapter": 2,
        "title": "Chapter 2: Something Occurs",
        "text": "The hovering vehicle came to a sudden halt in front of a massive, futuristic fortress made of gleaming silver and black metal. The family stepped out, hesitant and unsure of what awaited them. As they approached the entrance, the doors slid open with a hiss, revealing a dimly lit corridor lined with glowing symbols. Marie, always the curious one, reached out to touch one of the symbols, and the entire wall lit up with strange, swirling patterns. A deep, resonant voice echoed through the corridor, speaking in a language they couldn't understand.\n\nSuddenly, the walls began to move, shifting and reconfiguring themselves until the family was surrounded by a maze of metallic corridors. Panic set in as they realized they were separated, each of them standing alone in a different part of the labyrinth. Leah called out for her children, but her voice was swallowed by the twisting, turning walls. Samantha tried to stay calm, remembering the survival skills she'd learned in school, while Connor and Marie struggled to find their way back to each other.\n\nAs Ryan wandered through the maze, a glowing orb appeared before him, pulsating with a strange energy. It hovered in the air for a moment before shooting off down the corridor, as if beckoning him to follow. With no other options, Ryan took a deep breath and began to chase after the orb, hoping it would lead him to his family. But as he turned a corner, the floor beneath him gave way, and he found himself falling into darkness."
    },
    {
        "chapter": 3,
        "title": "Chapter 3: The Mystery Deepens",
        "text": "Ryan hit the ground with a thud, groaning as he slowly sat up. He found himself in a cavernous chamber filled with strange, floating crystals that emitted a soft, bluish light. The air was thick with an electric charge, and the walls hummed with an eerie, otherworldly energy. As Ryan explored the chamber, he noticed that the crystals were arranged in a specific pattern, almost like a map. He had no idea what it meant, but he knew it was important.\n\nMeanwhile, Leah was frantically trying to navigate the maze, using her wits to avoid traps and dead ends. She stumbled upon a hidden panel in the wall that opened into a small control room. Inside, she found a console displaying images of her family members, each trapped in a different part of the fortress. Leah's heart raced as she tried to figure out how to free them, her hands trembling as she pressed buttons and pulled levers. But every attempt seemed to make the situation worse, and she feared that one wrong move could spell disaster.\n\nSamantha, Connor, and Marie were facing their own challenges. Samantha found herself in a room filled with strange artifacts, each more perplexing than the last. Connor was being chased by a robotic guardian that seemed determined to keep him from reuniting with his family. And Marie, the youngest, discovered a hidden passage that led to a mysterious chamber filled with ancient symbols. As each of them tried to solve the puzzles before them, they couldn't shake the feeling that they were being watched by unseen eyes. And as the tension mounted, they all knew that time was running out."
    },
    {
        "chapter": 4,
        "title": "Chapter 4: The Guardian's Test",
        "text": "Ryan cautiously made his way through the crystal chamber, following the pattern on the floor. The strange map seemed to be guiding him to the center of the room, where a large, pulsating crystal hovered above a pedestal. As he approached, the crystal emitted a blinding light, and Ryan was suddenly surrounded by a group of robotic figures. The largest of them stepped forward, its eyes glowing with a fierce red light. In a booming voice, it declared, 'Only those who pass the Guardian's test may proceed.'\n\nRyan felt a surge of fear but steeled himself for whatever was to come. The robot extended its arm, revealing a series of intricate patterns that seemed to shift and change before his eyes. 'Solve the puzzle,' it commanded. Ryan quickly realized that the patterns were a complex series of equations, and the only way to solve them was to manipulate the floating crystals in the room. As he began moving the crystals, he felt the pressure of time ticking away, knowing that failure could mean the end for him and his family.\n\nMeanwhile, Leah, still in the control room, discovered a hidden terminal that allowed her to communicate with Ryan. 'I can see you,' she whispered urgently through the intercom. 'Whatever you do, don't let the Guardian see your fear. I'm trying to find a way to disable it, but you need to buy us some time.' Ryan nodded, even though Leah couldn't see him, and continued working on the puzzle, his heart pounding as the Guardian loomed over him."
    },
    {
        "chapter": 5,
        "title": "Chapter 5: Uncovering the Truth",
        "text": "Samantha had been examining the strange artifacts in the room when she accidentally triggered a hidden mechanism. The floor beneath her shifted, revealing a staircase leading down into a secret chamber. With no other choice, she descended into the darkness, her heart racing with a mixture of fear and curiosity. At the bottom of the stairs, she found herself in a room filled with holographic displays, each one showing scenes from different times and places. It was as if the entire history of the universe was laid out before her.\n\nAs Samantha explored the room, she stumbled upon a hologram that depicted a scene eerily similar to their current predicament. A family, much like hers, was trapped in the fortress, facing the same challenges they were now. But as she watched, the family seemed to disappear into thin air, leaving only a cryptic message behind: 'The truth lies in the heart of the fortress.' Samantha knew that this was a clue, but what it meant was still a mystery.\n\nAt the same time, Connor had narrowly escaped the robotic guardian that was chasing him. He found himself in a small chamber filled with ancient texts and scrolls. As he examined them, he realized that they contained detailed information about the fortress and its purpose. The fortress was not just a building, but a living entity, designed to test those who entered it. And the key to escaping was not just solving puzzles, but understanding the very nature of the fortress itself. Armed with this new knowledge, Connor set out to find his family, determined to unravel the mystery once and for all."
    },
    {
        "chapter": 6,
        "title": "Chapter 6:    The Final Challenge",
        "text": "Marie, who had ventured into the mysterious chamber filled with ancient symbols, found herself face to face with a strange being made entirely of light. The being spoke in a gentle, melodic voice, 'You are the youngest, but your heart is pure. The final challenge is yours to face.' Marie, though scared, nodded bravely. The being gestured toward a large, ornate door at the far end of the chamber. 'Beyond this door lies the core of the fortress. You must find the key to unlock it, and with it, the fate of your family will be decided.'\n\nAs Marie approached the door, she noticed that the symbols on it matched the ones in the chamber. Slowly, she began to piece together the puzzle, her small hands tracing the patterns as she worked. Each correct symbol caused the door to glow brighter, but one wrong move could lock it forever. Sweat beaded on her forehead as she focused all her energy on finding the right combination. The air was thick with tension, and the entire fortress seemed to hold its breath as she worked.\n\nBack in the crystal chamber, Ryan finally completed the Guardian's test. The robotic figures deactivated and the large crystal in the center of the room began to rotate, revealing a hidden passageway. Leah managed to override the control room’s system, allowing her, Samantha, and Connor to reunite with Ryan. Together, they made their way to Marie, only to find her standing before the massive door, her fingers hovering over the final symbol. As she prepared to make her choice, the walls around them began to tremble, and the door started to open on its own, revealing a blinding light from within. The final challenge awaited them, and their fate hung in the balance."
    }
];