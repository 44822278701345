import React,{Fragment, useState, useEffect} from 'react';
import { useLottie } from 'lottie-react';

import cat from './lottie/cat.json';
import pumpkins from './lottie/pumpkins.json';
import triplepump from './lottie/triplepump.json';
import ghost from './lottie/ghost.json';

import turkey from './lottie/turkey.json';
import grateful from './lottie/grateful.json';
import happythanks from './lottie/happythanks.json';
import leaves from './lottie/leaves.json';

import snow from './lottie/snow.json';
import christmastree from './lottie/christmastree.json';
import santa from './lottie/santa.json';
import merryChristmas from './lottie/merryChristmas.json';

import leprechaun from './lottie/leprechaun.json';

import summerFun from './lottie/summerFun.json';
import fireworks from './lottie/fireworks.json';
import pinwheel from './lottie/pinwheel.json';
import fallBike from './lottie/fallbike.json';
import garden from './lottie/garden.json';
import world from './lottie/world.json';


const halloween = [cat, pumpkins,triplepump, ghost];
const thanksgiving = [turkey,grateful, happythanks, leaves];
const christmas = [christmastree, santa, merryChristmas];

const winter = [snow];
const stPatricksDay = [leprechaun];
const springTime = [garden];
const fourthOfJuly = [fireworks,pinwheel];
const summer = [summerFun];
const fall = [fallBike];
const all = [world];
// Add more holiday-themed animation arrays as needed

const getAnimationsForCurrentMonth = () => {
    const month = new Date().getMonth();

    switch (month) {
        case 0: // January
        case 1: // February
            return winter;
        case 2: // March
            return stPatricksDay;
        case 3: // April
        case 4: // May
            return springTime;
        case 5: // June
            return summer;
        case 6: // July
            return fourthOfJuly
        case 7: // August
            return summer;
        case 8: // September
            return fall;
        // Add more cases for other months and holidays
        case 9: // October
            return halloween;
        case 10: // November
            return thanksgiving;
        case 11: // December
            return christmas;
        default:
            return all;
            // Return default animations or an empty array
            break;
    }
};

const HomeHeroBanner = () => {
    const [animations, setAnimations] = useState([]);

    useEffect(() => {
        setAnimations(getAnimationsForCurrentMonth());
    }, []);

    const randomIndex = Math.floor(Math.random() * animations.length);
    const { View } = useLottie(
        {
          animationData: animations[randomIndex],
          loop: true,
          autoplay: true,
        },
        { height: 500 },
      );

    return (
        <Fragment>
            <div className="hero-banner-two">
                {View}
            </div>
        </Fragment>
    )
}

export default HomeHeroBanner