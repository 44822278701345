import React, { useState, useEffect } from "react"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import TextField from "@mui/material/TextField"
import Button from "@mui/material/Button"
import {
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material"
import { budget } from "./ExpensesPage"

const ExpenseDialog = ({ open, onClose, onSave, expense }) => {
  // Default to today if no date is provided
  const [date, setDate] = useState("")
  const [description, setDescription] = useState("")
  const [amount, setAmount] = useState("")
  const [category, setCategory] = useState("")

  useEffect(() => {
    if (expense) {
      setDate(expense.date || "")
      setDescription(expense.description || "")
      setAmount(expense.amount || "")
      setCategory(expense.category || "")
    } else {
      setDate(new Date().toISOString().split("T")[0])
      setDescription("")
      setAmount("")
      setCategory("")
    }
  }, [expense])

  const handleSave = () => {
    onSave({
      id: expense?.id || undefined,
      date,
      description,
      amount,
      category,
    })
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{expense ? "Edit Expense" : "Add Expense"}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Date"
          type="date"
          fullWidth
          value={date}
          onChange={(e) => setDate(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          margin="dense"
          label="Description"
          type="text"
          fullWidth
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Amount"
          type="number"
          fullWidth
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
        <FormControl fullWidth margin="dense">
          <InputLabel>Category</InputLabel>
          <Select
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            label="Category"
          >
            {budget.map((budgetItem) => (
              <MenuItem key={budgetItem.category} value={budgetItem.category}>
                {budgetItem.category}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ExpenseDialog
