import * as React from "react"
import Box from "@mui/material/Box"
import SpeedDial from "@mui/material/SpeedDial"
import SpeedDialIcon from "@mui/material/SpeedDialIcon"
import SpeedDialAction from "@mui/material/SpeedDialAction"
import MonitorIcon from "@mui/icons-material/Monitor"
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun"
import CleaningServicesIcon from "@mui/icons-material/CleaningServices"
import TravelExploreIcon from "@mui/icons-material/TravelExplore"

const AddSpeedDial = ({ onAddChore, onImprove, onScreenList, onAsNeeded }) => {
  const actions = [
    { icon: <CleaningServicesIcon />, name: "Add Chore", onClick: onAddChore },
    {
      icon: <DirectionsRunIcon />,
      name: "Add Self Improvement",
      onClick: onImprove,
    },
    {
      icon: <MonitorIcon />,
      name: "Add Screen List Item",
      onClick: onScreenList,
    },
    { icon: <TravelExploreIcon />, name: "Add As Needed", onClick: onAsNeeded },
  ]

  return (
    <Box sx={{ height: 320, transform: "translateZ(0px)", flexGrow: 1 }}>
      <SpeedDial
        ariaLabel="SpeedDial basic example"
        sx={{ position: "absolute", bottom: 16, right: 16 }}
        icon={<SpeedDialIcon />}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={action.onClick}
          />
        ))}
      </SpeedDial>
    </Box>
  )
}

export default AddSpeedDial
