import React from "react";
import { AppBar, Button, Dialog, DialogContent, IconButton, Toolbar, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: "'Inconsolata', sans-serif",
  },
});


const ChapterDialog = ({ 
    open, 
    handleClose, 
    storyId, 
    chapterNumber, 
    target, 
    progress, 
    audioUrl,
    chapterState,
    chapterContent,
}) => {
  const [audio, setAudio] = React.useState(null);

  React.useEffect(() => {
    // Initialize audio when the component mounts
    setAudio(new Audio(audioUrl));

    return () => {
        // Pause and clean up the audio when the component unmounts
        if (audio) {
            audio.pause();
        }
    };
}, [audioUrl]);

const onClose = () => {
  // Pause the audio when closing the dialog
  if (audio) {
      audio.pause();
  }
  handleClose();

}

    let content = [];
    console.log(`recieved state is ${chapterState}`);
    if (chapterState === "current") {
        console.log("setting content to current");
        content = [`${(progress % target) / target * 100}% unlocked!  Keep Going!`];
    } else if (chapterState === "locked") {
        console.log("setting content to locked");
        //display locked message
        content = ["Finish more chores to unlock this chapter!"];
    } else if (chapterContent) {
        console.log("setting content to unlocked");
        content = chapterContent;
    }

  const displayUnlocked = chapterState === "readyToGenerate";

  const unlockView = (
    <DialogContent>
        <ThemeProvider theme={theme}>
            <Typography variant="body1" gutterBottom sx={{ mb: 2}}>
                You've unlocked the next chapter!  Click here to generate the story!
            </Typography>
            <Button variant="contained">GENERATE</Button>
        </ThemeProvider>
    </DialogContent>
  );

  const displayAudio = audioUrl && chapterState === "unlocked";

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Chapter {chapterNumber + 1}
            </Typography>
          </Toolbar>
        </AppBar>
        {content && 
            <DialogContent>
            {displayAudio && audioUrl && <audio controls src={audioUrl} style={{ width: '100%' }} />}
                <ThemeProvider theme={theme}>
                    {content.map((text, index) => (
                        <Typography key={index} variant="body1" gutterBottom sx={{ mb: 2}}>
                            {text}
                        </Typography>
                    ))}
                </ThemeProvider>
            </DialogContent>
        }
        {displayUnlocked && unlockView}
    </Dialog>
    
  )
}

export default ChapterDialog