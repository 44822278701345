import React, { useEffect, useMemo } from "react"

// MUI Components
import {
  Container,
  Grid,
  Typography,
  Box,
  Paper,
  Button,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  LinearProgress,
  Divider,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  ToggleButton,
  ToggleButtonGroup,
  Dialog,
} from "@mui/material"

// MUI Icons
import LockIcon from "@mui/icons-material/Lock"
import LockOpenIcon from "@mui/icons-material/LockOpen"

// Custom Components
import SiteWrapper from "../../home-pages/SiteWrapper"
import ChapterCard from "./ChapterCard"

// External Libraries
import { useSnackbar } from "notistack"
import { motion } from "framer-motion"
import { useSelector } from "react-redux"

// API
import { useDispatch } from "react-redux"
import {
  generateNewChapter,
  initializeNewStory,
  updatePointTotals,
  resetStoryState,
} from "../../../redux/storySlice"
import FamilySetupDialog from "./FamilySetupDialog"

const storyStates = ["INIT_STATE", "IN_PROGRESS", "STORY_COMPLETE"]

// using .map make an array of 10 chapters all unlocked
const allChapters = Array.from({ length: 10 }, (_, i) => ({
  id: i + 1,
  title: `Chapter ${i + 1}`,
  status: "unlocked",
}))

const stages = [
  "Init State",
  "Story 1 Ready",
  "Story 2 not yet earned",
  "Final Story ready to be unlocked",
  "All Stories unlocked",
]

const ChapterButton = ({ title, status, variant, onClick }) => {
  return (
    <motion.div
      initial={{ scale: 1 }}
      animate={status === "available" ? { scale: [1, 1.1, 1] } : { scale: 1 }}
      transition={
        status === "available" ? { duration: 2, repeat: Infinity } : {}
      }
    >
      <Button
        variant={variant}
        color={status === "locked" ? "error" : "primary"}
        onClick={onClick}
        startIcon={
          status === "locked" ? (
            <LockIcon />
          ) : status === "available" ? (
            <LockOpenIcon />
          ) : null
        }
        disabled={status === "locked"}
        fullWidth
      >
        <Typography variant="button">{title}</Typography>
      </Button>
    </motion.div>
  )
}

const AdvenchoreStoryPage = () => {
  const [currentChapter, setCurrentChapter] = React.useState(0)
  // const [chapterData, setChapterData] = React.useState(chapters)
  const [currentPoints, setCurrentPoints] = React.useState(0)
  const targetPoints = 100

  //confirmation dialog
  const [showDialog, setShowDialog] = React.useState(false)
  const [dialogMessage, setDialogMessage] = React.useState("")
  const [dialogTitle, setDialogTitle] = React.useState("")
  const [dialogCallback, setDialogCallback] = React.useState(() => {})
  const [dialogConfirmText, setDialogConfirmText] = React.useState("Unlock")

  //character dialog
  const [showCharacterDialog, setShowCharacterDialog] = React.useState(false)

  const dispatch = useDispatch()
  const { chapterData, storyState } = useSelector((state) => state.story)

  const progress = useMemo(
    () => (currentPoints / targetPoints) * 100,
    [currentPoints, targetPoints]
  )

  useEffect(() => {
    dispatch(updatePointTotals({ currentPoints, targetPoints }))
  }, [currentPoints, targetPoints])

  const { enqueueSnackbar } = useSnackbar()

  const handleChapterClick = (chapter, index) => {
    if (chapter.status === "available") {
      setShowDialog(true)
      setDialogTitle("Unlock Chapter?")
      setDialogMessage("Are you sure you want to unlock this chapter?")
      setDialogConfirmText("Unlock")
      setDialogCallback(() => () => handleUnlock(index))
    } else if (chapter.status === "unlocked") {
      setCurrentChapter(index)
    } else {
      enqueueSnackbar(
        "This chapter is locked! Please complete the previous chapters first."
      )
    }
  }

  const onRequestInitNewStory = () => {
    setDialogTitle("Start New Story?")
    setDialogMessage("Are you ready to start a new story?")
    setDialogConfirmText("Start")
    setDialogCallback(() => onInitNewStory)
    setShowDialog(true)
  }

  const onRequestArchiveStory = () => {
    setDialogTitle("Archive Story?")
    setDialogMessage("Save this story so you can start a new one?")
    setDialogConfirmText("Archive")
    setDialogCallback(() => onArchiveStory)
    setShowDialog(true)
  }

  const onArchiveStory = () => {
    console.log("Resetting story!")
    setShowDialog(false)
    dispatch(resetStoryState())
    setCurrentChapter(null)
    enqueueSnackbar("Story archived!")
  }

  const onInitNewStory = () => {
    dispatch(initializeNewStory("a"))
    console.log("Initializing new story!")
    enqueueSnackbar("New story initialized!")
    setShowDialog(false)
    // setChapterData(chapters)
    // setCurrentChapter(0)
  }

  console.log("chapterData", chapterData)

  const handleUnlock = (index) => {
    console.log("handle unlock")
    dispatch(generateNewChapter())
    setCurrentPoints(0) // this is just for debugging
    setCurrentChapter(index)
    enqueueSnackbar("Chapter unlocked!")
    setShowDialog(false)
  }

  const onSimPointsComplete = () => {
    if (storyState !== "INIT_STATE") {
      setCurrentPoints(targetPoints)
    }
  }

  const onEditCharacters = () => {
    enqueueSnackbar("Edit characters clicked!")
    setShowCharacterDialog(true)
  }

  console.log("Story State", storyState)

  return (
    <SiteWrapper>
      <Button onClick={onSimPointsComplete}>Simulate point completion</Button>
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          {chapterData.map((chapter, index) => (
            <Grid item key={chapter.id}>
              <ChapterButton
                title={chapter.title}
                onClick={() => handleChapterClick(chapter, index)}
                status={chapter.status}
                variant={index === currentChapter ? "contained" : "outlined"}
              />
            </Grid>
          ))}
        </Grid>
        {/* Progress and Impact Section */}
        {storyState !== "INIT_STATE" && (
          <Paper elevation={3} sx={{ padding: 2, marginTop: 2 }}>
            <Grid container spacing={2}>
              {storyState !== "STORY_COMPLETE" && (
                <Grid item xs={12}>
                  <Typography variant="subtitle1">
                    Next Chapter Unlock Progress
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {`Complete chores to earn more points!`}
                  </Typography>
                  <br />
                  {/* Display the current value and total value */}
                  <Typography variant="body2" color="textSecondary">
                    {`${currentPoints}/${targetPoints} points`}
                  </Typography>
                  <LinearProgress variant="determinate" value={progress} />
                </Grid>
              )}
              {storyState === "STORY_COMPLETE" && (
                <Grid item xs={12}>
                  <Typography variant="h6">
                    Congratulations! You've reached the end of the story!
                  </Typography>

                  <Button
                    variant="contained"
                    color="primary"
                    onClick={onRequestArchiveStory}
                  >
                    Archive Story
                  </Button>
                </Grid>
              )}
            </Grid>
          </Paper>
        )}
        <Grid container spacing={2} sx={{ marginTop: 2 }}>
          {/* Sidebar with Character Profiles */}
          <Grid item xs={3}>
            <Paper elevation={3} sx={{ padding: 2 }}>
              <Typography variant="h6">Characters</Typography>
              <List>
                {/* Example Character List Items */}
                <ListItem button>
                  <ListItemAvatar>
                    <Avatar src="/path/to/character1.png" />
                  </ListItemAvatar>
                  <ListItemText
                    primary="Ryan the Brave"
                    secondary="Stats & Achievements"
                  />
                </ListItem>
                <ListItem button>
                  <ListItemAvatar>
                    <Avatar src="/path/to/character2.png" />
                  </ListItemAvatar>
                  <ListItemText
                    primary="Leah the Wise"
                    secondary="Stats & Achievements"
                  />
                </ListItem>
                <ListItem button>
                  <ListItemAvatar>
                    <Avatar src="/path/to/character2.png" />
                  </ListItemAvatar>
                  <ListItemText
                    primary="Samantha the Strong"
                    secondary="Stats & Achievements"
                  />
                </ListItem>
                <ListItem button>
                  <ListItemAvatar>
                    <Avatar src="/path/to/character2.png" />
                  </ListItemAvatar>
                  <ListItemText
                    primary="Connor the Clever"
                    secondary="Stats & Achievements"
                  />
                </ListItem>
                <ListItem button>
                  <ListItemAvatar>
                    <Avatar src="/path/to/character2.png" />
                  </ListItemAvatar>
                  <ListItemText
                    primary="Marie the Mysterious"
                    secondary="Stats & Achievements"
                  />
                </ListItem>
              </List>
              {/* Edit Button */}
              <Button
                variant="outlined"
                onClick={onEditCharacters}
                color="primary"
              >
                Edit Characters
              </Button>
            </Paper>
          </Grid>

          {/* Main Story Content */}
          <Grid item xs={9}>
            {/* If init state display the kickoff button */}
            {storyState === "INIT_STATE" && (
              <Paper
                elevation={3}
                sx={{
                  padding: 2,
                  height: 200,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <motion.div
                  initial={{ scale: 1 }}
                  animate={
                    storyState === "INIT_STATE"
                      ? { scale: [1, 1.1, 1] }
                      : { scale: 1 }
                  }
                  transition={
                    storyState === "INIT_STATE"
                      ? { duration: 2, repeat: Infinity }
                      : {}
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={onRequestInitNewStory}
                  >
                    Ready to Start a New Story!
                  </Button>
                </motion.div>
              </Paper>
            )}
            {storyState !== "INIT_STATE" &&
              chapterData &&
              chapterData[currentChapter] && (
                <ChapterCard chapter={chapterData[currentChapter]} />
              )}
          </Grid>
        </Grid>
        <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
          <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {dialogMessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowDialog(false)}>Cancel</Button>
            <Button onClick={dialogCallback} autoFocus>
              {dialogConfirmText}
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
      <FamilySetupDialog
        open={showCharacterDialog}
        onClose={() => setShowCharacterDialog(false)}
      />
    </SiteWrapper>
  )
}

export default AdvenchoreStoryPage
