import React, {Fragment, useState, useEffect} from 'react';
import {Helmet} from 'react-helmet';
import { useParams } from 'react-router-dom';

import TopNavFour from '../../../components/header/TopNavFour';
import CopyRightFour from '../../../components/footer/CopyRightFour';

import { getDoc, doc, collection, query, getDocs, where } from 'firebase/firestore';
import { db } from '../../../firebase/firebase';
import MonthNewslettersGrid from '../../../components/blog/MonthNewslettersGrid';
import NewsletterBreadCrumb from '../../../components/page-title/NewsletterBreadCrumb';
import NewsletterLinks, { DynamicNewsletterLinks, DynamicYearLinks, MonthsNewsletterLinks } from '../../../components/blog/NewsletterLinks';
import TopNavHome from '../../../components/header/TopNavHome';
import SiteWrapper from "../../home-pages/SiteWrapper"

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "Aaugust",
  "September",
  "October",
  "November",
  "December",
]

const prettyPrint = (input) => {
  if (input === undefined) {
    return input
  }
  input = input.toLowerCase()
  return input.charAt(0).toUpperCase() + input.slice(1)
}

const useYearlyData = (year) => {
  const [blogData, setBlogData] = useState([])

  useEffect(() => {
    let mounted = true
    const getBlogData = async () => {
      let newsletters = []

      if (year !== undefined) {
        for (const m of months) {
          const q = query(collection(db, "SiblingNewsletter", year, m))
          const querySnapshot = await getDocs(q)
          querySnapshot.forEach((doc) => {
            if (doc.data()["text"].length > 0) {
              let data = doc.data()
              data["month"] = m.toLowerCase()
              data["year"] = year
              data["sibling"] = doc.id
              newsletters.push(data)
            }
          })
        }
      }
      setBlogData(newsletters)
    }
    getBlogData()
    return () => (mounted = false)
  }, [])
  return blogData
}

const AllNewslettersPage = () => {
  // const monthlyData = useMonthlyData(year, month);
  // const yearlyData = useYearlyData(year);

  // const newsletterData = month === undefined ? yearlyData : monthlyData;

  const title = "All Newsletters"

  return (
    <SiteWrapper>
      <div className="main-page-wrapper">
        <Helmet>
          <title>{title}</title>
        </Helmet>

        <div className="theme-inner-banner">
          <NewsletterBreadCrumb />
          <img
            src="images/shape/shape_38.svg"
            alt=""
            className="shapes shape-one"
          />
          <img
            src="images/shape/shape_39.svg"
            alt=""
            className="shapes shape-two"
          />
        </div>

        <div className="blog-details pt-90 mb-150 lg-pt-40 lg-mb-100">
          <div className="container">
            <div className="row">
              <div className="col-xxl-11 m-auto">
                <div className="row">
                  <div className="col-lg-12 col-md-6">
                    <div className="blog-sidebar ps-xl-5 ps-lg-3 me-xxl-5 mt-45 md-mt-70">
                      <div className="sidebar-category mb-50">
                        <h5 className="sidebar-title">Archive</h5>
                        <NewsletterLinks />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-style-four space-fix-one theme-basic-footer">
          <div className="container">
            <div className="inner-wrapper">
              <div className="bottom-footer">
                <CopyRightFour />
              </div>
            </div>
          </div>
        </div>
      </div>
    </SiteWrapper>
  )
}

export default AllNewslettersPage