import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Fab, Container, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { onSnapshot, doc, setDoc } from 'firebase/firestore';
import { db } from '../../../firebase/firebase';
import AddOrEditDialog from './components/AddOrEditDialog';
import PageWrapper from '../../PageWrapper';

function FamilyAddressTable() {
  const [addresses, setAddresses] = useState([]);
  const [filter, setFilter] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [currentData, setCurrentData] = useState({ lastName: '', address: '' });
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredAddresses = addresses.filter(row => {
    return row.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      row.address?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      row.category?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      row.address2?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      row.city?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      row.state?.toLowerCase().includes(searchQuery.toLowerCase());
      });


  useEffect(() => {
    const docRef = doc(db, 'familyAddresses', 'addresses');

    const unsubscribe = onSnapshot(docRef, (doc) => {
      if (doc.exists()) {
        setAddresses(doc.data().data); // Assuming 'data' is the field containing the array
      } else {
        console.log("No such document!");
      }
    });

    // Clean up the listener when the component unmounts
    return () => unsubscribe();
  }, []);

  const handleRowClick = (data) => {
    setCurrentData(data);
    setDialogOpen(true);
  };

  const handleAddClick = () => {
    setCurrentData({ lastName: '', address: '' });
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleSave = async (updatedAddresses) => {
    try {
      // Reference to the document in Firestore
      const docRef = doc(db, 'familyAddresses', 'addresses');
  
      // Update the document with the new data
      await setDoc(docRef, { data: updatedAddresses });
  
      console.log("Document successfully updated!");
    } catch (error) {
      console.error("Error updating document: ", error);
    }
  };

  return (
    <PageWrapper>
      <Container maxWidth="lg">
      {/* <div>
        {['Smith', 'Johnson', 'Williams'].map(chip => (
          <Chip
            key={chip}
            label={chip}
            onClick={() => handleFilterChange(chip)}
            style={{ margin: 2 }}
          />
        ))}  
      </div> */}

      {/* Address Table */}
        
      <TextField
        label="Search"
        variant="outlined"
        value={searchQuery}
        onChange={handleSearchChange}
      />
      <Fab color="primary" aria-label="add" onClick={handleAddClick}>
        <AddIcon />
      </Fab>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Address</TableCell>
              <TableCell>City</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredAddresses.map((row) => (
              <TableRow key={row.id} onClick={() => handleRowClick(row)}>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.category}</TableCell>
                <TableCell>{row.address}<br />{row.address2}</TableCell>
                <TableCell>{`${row.city}, ${row.state} ${row.zip}`}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table> 
      </TableContainer>
      <AddOrEditDialog
        open={dialogOpen}
        handleClose={handleDialogClose}
        handleSave={handleSave}
        initialData={currentData}
        allData={addresses}
      />
      </Container>  
    </PageWrapper>
    
  );
}

export default FamilyAddressTable;
