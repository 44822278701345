import { Box, Grid, Container } from '@mui/material';
import React,{Fragment} from 'react';
import {Link} from 'react-router-dom';
import { Image } from '@mui/icons-material';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

// const slides = ["images/ftam/ss_1.jpg", "images/ftam/ss_2.jpg", "images/ftam/ss_3.jpg"];

// uses a format string to create a list of images from ss_1 to ss_12
const slides = [...Array(12).keys()].map((i) => `images/ftam/ss_${i + 1}.jpg`);
console.log(slides);



const CarouselSliderBanner = () => {
    return (
        <Container style={{padding: "20px"}}>
            <Carousel autoPlay infiniteLoop>
                {slides.map((slide) => (
                <div>
                    <img src={slide} />
                    {/* <p className="legend">Text</p> */}
                </div>
                ))}
            </Carousel>
        </Container>
        
    )
}

export default CarouselSliderBanner