import { auth, db } from './firebase';
import { doc, getDoc, updateDoc, addDoc, setDoc, collection, deleteDoc } from "firebase/firestore";
import {createUserWithEmailAndPassword, updateProfile, sendPasswordResetEmail, sendEmailVerification} from 'firebase/auth';
import moment from 'moment';

export const dateFormat = "MMMM D";

export function registerNewUser(data, successCallback, failureCallback) {
  let error = false;
  let user;
  createUserWithEmailAndPassword(auth, data.email, data.password)
  .then(function() {
  user = auth.currentUser;

  })
  .then(() => {
    updateProfile(auth.currentUser, {
      displayName: data.firstName + " " + data.lastName,
    });
  })
  .then(() => {
    sendEmailVerification(user);
    
  })
  .then(() => {
    successCallback();
  })
  .catch(function(error) {
    // console.log(error.code);
    switch(error.code) {
      case "auth/email-already-in-use":
        failureCallback("Email already in use");
        break;
      default:
        failureCallback("Problem with registration, please try again");
    }
    });
  } 

export function registerNewUserSendResetEmail(data) {
  createUserWithEmailAndPassword(auth, data.email, random()).then(function() {
  var user = auth.currentUser;

  sendPasswordResetEmail(auth, data.email)
    .then(() => {      
    //  console.log('sent reset email');
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
    });

  }).catch(function(error) {
    });
}

//Utils

export function capitalizeFirstLetter(input) {
  return (input && input.length > 0) ? input.charAt(0).toUpperCase() + input.slice(1) : input;
}

export const isValidEmail = (email) => {
  if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
    return true;
  }
  return false;
}

const random = (length = 20) => {
  // Declare all characters used in UUID
  let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  // Pick characers randomly
  let str = '';
  for (let i = 0; i < length; i++) {
      str += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return str;
};