import MenuBookIcon from '@mui/icons-material/MenuBook';
import LockIcon from '@mui/icons-material/Lock';
import { CircularProgress, IconButton } from "@mui/material";
import React from "react";
import styled from "styled-components";
import PropTypes from 'prop-types';
// import { useLottie } from 'lottie-react';

// import explode from './lottie/explode.json';

const StyledChapterIcon = styled('div')(({ state }) => ({
    position: 'relative',
    backgroundColor: state === "unlocked" ? "gold" : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50, // width of the icon root
    height: 50, // height of the icon root
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  }));

const ChapterIcon = (props) => {
    const { state, progress, target, onChapterSelect } = props;

    // const { View, play } = useLottie(
    //   {
    //     animationData: explode,
    //     loop: true,
    //     autoplay: true,
    //   },
    //   { height: 50 },
    // );


    const percentProgress = Math.round(progress / target * 100);

    const icons = {
        unlocked: <MenuBookIcon />,
        current: <MenuBookIcon />,
        locked: <LockIcon />,
    }

    const icon = icons[state];

    const handleClick = () => {
      onChapterSelect();
      // play();
      // console.log("clicked");
    }
  
    return (
      // <>{View}</>
      <StyledChapterIcon state={state} onClick={handleClick}>
        {progress !== undefined && state === "current" && (
          <>
            <CircularProgress
              variant="determinate"
              value={100}
              size={70}
              thickness={4}
              sx={{
                position: 'absolute',
                top: '-10px',
                left: '-10px',
                zIndex: 0,
                color: '#E8E8E8'
              }}
            />
            <CircularProgress
              variant="determinate"
              value={percentProgress + 2}
              size={70}
              thickness={4}
              sx={{
                position: 'absolute',
                top: '-10px',
                left: '-10px',
                zIndex: 1,
                color: 'white'
              }}
            />
            {state === "current" && (
              <CircularProgress
                variant="determinate"
                value={percentProgress}
                size={70}
                thickness={4}
                sx={{
                  position: 'absolute',
                  top: '-10px',
                  left: '-10px',
                  zIndex: 2,    
                }}
              />
            )}
          </>
        )}
        <IconButton>
        {icon}
        </IconButton>
      </StyledChapterIcon>
    );
  }

    

ChapterIcon.propTypes = {
    active: PropTypes.bool,
    completed: PropTypes.bool,
    progress: PropTypes.number,
  };

export default ChapterIcon;