import React, { useContext } from 'react';
import { Route, Navigate } from "react-router-dom"
import { useAuthState } from "react-firebase-hooks/auth"
import { auth } from "../firebase/firebase"

const ProtectedRoute = ({ children }) => {
  const [user, loading] = useAuthState(auth)

  if (loading) {
    return <>Loading...</> // Show loading state while checking auth status
  }

  if (!user) {
    return (
      <>
        <Navigate to="/login" />
      </>
    )
  }

  return children
}

export default ProtectedRoute;