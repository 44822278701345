import React, {Fragment} from 'react'
import { Link } from 'react-router-dom';
import { prettyPrint, familyNames } from '../../views/inner-pages/blog/SiblingNewsletterPage';

const viewTypes = {
    ALL: "All", 
    YEAR: "Year", 
    MONTH: "Month", 
    NEWSLETTER: "Newsletter"
};

const NewsletterBreadCrumb = ({year, month, sibling}) => {
    let viewType;
    let currPage;

    if (sibling !== undefined) {
        viewType = viewTypes.NEWSLETTER;
        currPage = (<li className="current-page">{familyNames[sibling]}</li>);
    } else if (month !== undefined) {
        viewType = viewTypes.MONTH;
        currPage = (<li className="current-page">{prettyPrint(month)}</li>);
    } else if (year !== undefined) {
        viewType = viewTypes.YEAR;
        currPage = (<li className="current-page">{year}</li>);
    } else {
        viewType = viewTypes.ALL;
        currPage = (<li className="current-page">ALL</li>);
    }

    const allItem = (<li><Link to={`/newsletter/`}>ALL</Link></li>);
    const yearItem = (<li><Link to={`/newsletter/${year}`}>{year}</Link></li>);
    const monthItem = (<li><Link to={`/newsletter/${year}/${month}`}>{prettyPrint(month)}</Link></li>);

    
    return (
        <Fragment>
            <div className="container">
                <h2 className="intro-title text-center">Sibling Newsletter</h2>
                <ul className="page-breadcrumb style-none d-flex justify-content-center">
                    {viewType !== viewTypes.ALL && allItem}
                    {viewType !== viewTypes.ALL && viewType !== viewTypes.YEAR && yearItem}
                    {viewType !== viewTypes.ALL && viewType !== viewTypes.YEAR && viewType !== viewTypes.MONTH && monthItem}
                    {currPage}
                </ul>
            </div>
        </Fragment>
    )


    if (sibling) {
        return (
            <Fragment>
                <div className="container">
                    <h2 className="intro-title text-center">Sibling Newsletter</h2>
                    <ul className="page-breadcrumb style-none d-flex justify-content-center">
                        <li>
                            <Link to={`/newsletter/`}>ALL</Link>
                        </li>
                        <li>
                            <Link to={`/newsletter/${year}`}>{year}</Link>
                        </li>
                        <li>
                            <Link to={`/newsletter/${year}/${month}`}>{prettyPrint(month)}</Link>
                        </li>
                        <li className="current-page">{familyNames[sibling]}</li>
                    </ul>
                </div>
            </Fragment>
        )
    } else {
        return (
            <Fragment>
                <div className="container">
                    <h2 className="intro-title text-center">Sibling Newsletter</h2>
                    <ul className="page-breadcrumb style-none d-flex justify-content-center">
                        <li>
                            <Link to={`/newsletter/`}>ALL</Link>
                        </li>
                        <li>
                            <Link to={`/newsletter/${year}`}>{year}</Link>
                        </li>
                        <li>
                            <Link to={`/newsletter/${year}/${month}`}>{prettyPrint(month)}</Link>
                        </li>
                        <li className="current-page">
                        {prettyPrint(month)}
                        </li>
                    </ul>
                </div>
            </Fragment>
        )
    }

}

export default NewsletterBreadCrumb