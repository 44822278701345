import React, {Fragment} from 'react';
import {Helmet} from 'react-helmet';


import TopNavHome from '../../../components/header/TopNavHome';
import InnerBanner from '../../../components/page-title/InnerBanner';
import PortfolioGalleryFour from '../../../components/portfolio/PortfolioGalleryFour';
import BannerOne from '../../../components/short-banner/BannerOne';
import FooterFour from '../../../components/footer/FooterFour';
import CopyRightFour from '../../../components/footer/CopyRightFour';
import NewsLetterForm from '../../../components/form/NewsLetterForm';

const Portfolio2Column = () => {
    return (
        <Fragment>
            <div className="main-page-wrapper">
                <Helmet>
                    <title>Portfolio || RyanDymock.com</title>
                </Helmet>
                {/* helmet end */}

                <TopNavHome/> {/* theme-menu-four */}

                <div className="theme-inner-banner">
                    <InnerBanner intro="Portfolio" currpage="Portfolio"/>
                    <img src="images/shape/shape_38.svg" alt="" className="shapes shape-one"/>
                    <img src="images/shape/shape_39.svg" alt="" className="shapes shape-two"/>
                </div>
                {/* /.theme-inner-banner */}

                <div className="portfolio-gallery-four mt-140 mb-130 lg-mt-90 lg-mb-50">
                    <div className="container">
                        Coming Soon!
                        {/* <PortfolioGalleryFour/> TODO: Uncomment this and update the content*/}
                    </div>
                </div>


            </div>
        </Fragment>
    )
}

export default Portfolio2Column