import React, {Fragment, useState, useEffect} from 'react';
import {Helmet} from 'react-helmet';
import TopNavHome from "../../../components/header/TopNavHome"
import { IconButton, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

import { db, useFirebaseAuthentication } from '../../../firebase/firebase';
import { doc, getDoc } from "firebase/firestore";
import PictureGrid from './PictureGrid';
import { useNavigate } from 'react-router-dom';

const exampleArray = [
    {
       src: "https://storage.googleapis.com/photo-organization-1e84f.appspot.com/pictures/01_-_premarriage_era/1980/14964.jpg",
       original: "https://storage.googleapis.com/photo-organization-1e84f.appspot.com/pictures/01_-_premarriage_era/1980/14964.jpg",
       width: 320,
       height: 174,
       isSelected: false,
       caption: "oh boy",
    },
    {
       src: "https://storage.googleapis.com/photo-organization-1e84f.appspot.com/pictures/03_-_ryan_era/12861_b.jpg",
       original: "https://storage.googleapis.com/photo-organization-1e84f.appspot.com/pictures/03_-_ryan_era/12861_b.jpg",
       tags: [
          { value: "Christmas", title: "Christmas" },
       ],
       alt: "Boats (Jeshu John - designerspics.com)",
    },
    {
       src: "https://storage.googleapis.com/photo-organization-1e84f.appspot.com/pictures/03_-_ryan_era/0257-edited.jpg",
       original: "https://storage.googleapis.com/photo-organization-1e84f.appspot.com/pictures/03_-_ryan_era/0257-edited.jpg",
       width: 320,
       height: 212,
    },
    {
       src: "https://storage.googleapis.com/photo-organization-1e84f.appspot.com/pictures/01_-_premarriage_era/1980/14964.jpg",
       original: "https://storage.googleapis.com/photo-organization-1e84f.appspot.com/pictures/01_-_premarriage_era/1980/14964.jpg",
       width: 320,
       height: 174,
       isSelected: false,
       caption: "oh boy",
    },
    {
       src: "https://storage.googleapis.com/photo-organization-1e84f.appspot.com/pictures/03_-_ryan_era/12861_b.jpg",
       original: "https://storage.googleapis.com/photo-organization-1e84f.appspot.com/pictures/03_-_ryan_era/12861_b.jpg",
       tags: [
          { value: "Christmas", title: "Christmas" },
       ],
       alt: "Boats (Jeshu John - designerspics.com)",
    },
    {
       src: "https://storage.googleapis.com/photo-organization-1e84f.appspot.com/pictures/03_-_ryan_era/0257-edited.jpg",
       original: "https://storage.googleapis.com/photo-organization-1e84f.appspot.com/pictures/03_-_ryan_era/0257-edited.jpg",
       width: 320,
       height: 212,
    },
 ];
  
const PictureViewingPage = () => {
    const [pictures, setPictures] = useState([]); // All pictures
    const [era, setEra] = useState("01 - PreMarriage Era"); // Era to display
    const auth = useFirebaseAuthentication();

    useEffect(() => {
        if (!auth) {
            return;
        }
        console.log("era changed");
        const fetchPictures = async () => {
          try {
            const docRef = doc(db, "photos", era);
            const docSnap = await getDoc(docRef);   
            
            if (docSnap.exists) {
                let allPics = docSnap.data().pictures;
                // add src and original fields to each picture
                allPics = allPics.map((p) => {
                    return {
                        ...p,
                        src: p.storageURL,
                        original: p.storageURL,
                        caption: p.photoId,
                    }
                });
                setPictures(allPics);
            } else {
                console.log("No such document!");
            }
          } catch (error) {
            console.error('Error fetching pictures:', error);
          }
        };
    
        fetchPictures();
    }, [era, auth]);



    // const handleDownload = async () => {
    //     try {
    //         const response = await fetch(currentImage.storageURL);
    //         if (!response.ok) throw new Error(`Error: ${response.statusText}`);
    
    //         const blob = await response.blob();
    //         const url = window.URL.createObjectURL(blob);
    
    //         const link = document.createElement('a');
    //         link.href = url;
    //         link.download = currentImage.photoId || 'download';
    //         document.body.appendChild(link);
    //         link.click();
    //         document.body.removeChild(link);
    
    //         // Clean up by revoking the Blob URL
    //         window.URL.revokeObjectURL(url);
    //     } catch (error) {
    //         console.error('Download error:', error);
    //     }
    // };

    // const rotateLeft = () => {
    //     console.log("rotateLeft");
    // };

    // const updateFirestore = async () => {
    //     const picRef = doc(db, "photos", currentImage.era);
    //     const picSnap = await getDoc(picRef);
    //     if (picSnap.exists) {
    //         let allPics = picSnap.data().pictures;
    //         // add src and original fields to each picture
    //         allPics = allPics.map((p) => {
    //             return {
    //                 ...p,
    //                 src: p.storageURL,
    //                 original: p.storageURL,
    //                 caption: p.photoId,
    //             }
    //         });
    //         setPictures(allPics);
    //     } else {
    //         console.log("No such document!");
    //     }
    // };


    // const rotateRight = () => {
    //     console.log("rotateRight");
    //     // rotate current picture and update firestore
        

    // };

    const dropdown = (
        <FormControl>
            <InputLabel id="demo-simple-select-label">Era</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={era}
                label="Era"
                onChange={(e) => setEra(e.target.value)}
            >
                <MenuItem value={"01 - PreMarriage Era"}>PreMarriage Era</MenuItem>
                <MenuItem value={"02 - Newlywed Era"}>Newlywed Era</MenuItem>
                <MenuItem value={"03 - Ryan Era"}>Ryan Era</MenuItem>
                <MenuItem value={"04 - Kellianne Era"}>Kellianne Era</MenuItem>
            </Select>
        </FormControl>
    );
    
    const title = "Family Pictures";

    return (
        <Fragment>
            <div className="main-page-wrapper">
                <Helmet>
                    <title>{title}</title>
                </Helmet>

                <TopNavHome/>

                <br />
                <br />
                <br />
                {dropdown}
                <PictureGrid pictures={pictures}/>
            </div>
    </Fragment>
    );
}

export default PictureViewingPage