import React, { Fragment, useState, useEffect, useRef } from 'react';
import { 
  Button,
  Container,
  Fab, Grid, IconButton, LinearProgress, TextField, Typography,  } from '@mui/material';
import { collection, query, onSnapshot, doc, getDoc } from 'firebase/firestore';
import { db, useFirebaseAuthentication } from '../../../firebase/firebase';
import { Helmet } from "react-helmet"

import DesktopChoresView from "./DesktopChoresView"
import AddEditChoreDialog from "./AddEditChoreDialog"
import SiteWrapper from "../../home-pages/SiteWrapper"
import AddSpeedDial from "./AddSpeedDial"
import AddOtherTasksDialog from "./AddOtherTasksDialog"

export const assigneeOptions = [
  "Ryan",
  "Leah",
  "Samantha",
  "Connor",
  "Marie",
  "All",
  "All Kids",
]

export const assignees = ["Ryan", "Leah", "Samantha", "Connor", "Marie"]

export const avatars = {
  ryan: `${process.env.PUBLIC_URL}/images/ryan.png`,
  leah: `${process.env.PUBLIC_URL}/images/leah.png`,
  samantha: `${process.env.PUBLIC_URL}/images/sam.png`,
  connor: `${process.env.PUBLIC_URL}/images/connor.png`,
  marie: `${process.env.PUBLIC_URL}/images/marie.png`,
}

const initChore = {
  id: null,
  description: "",
  assignee: "",
  frequency: "daily",
}

const ChoresView = () => {
  const [points, setPoints] = useState(0)
  const [chores, setChores] = useState([])
  const [openDialog, setOpenDialog] = useState(false)
  const [newChore, setNewChore] = useState(initChore)
  const [isLoading, setIsLoading] = useState(true)
  const [storyId, setStoryId] = useState("")
  const [showGames, setShowGames] = useState(false)
  const [games, setGames] = useState([])
  const [openOtherDialog, setOpenOtherDialog] = useState(false)
  const [otherDialogType, setOtherDialogType] = useState("")

  const targetRef = useRef()

  const authUser = useFirebaseAuthentication()

  useEffect(() => {
    if (authUser && authUser.uid) {
      const userRef = doc(db, "Users", authUser.uid)

      // Fetch the familyId from the user's document
      getDoc(userRef)
        .then((docSnapshot) => {
          if (docSnapshot.exists()) {
            const userData = docSnapshot.data()
            console.log(`Received user data`)
            console.log(userData)
            const familyId = userData.familyId
            // Now fetch and listen to the family's points
            listenToFamilyPoints(familyId)
          } else {
            console.log("User not found")
          }
        })
        .catch((err) => {
          console.log(`Error fetching user: ${err}`)
        })
    }
  }, [authUser])

  const listenToFamilyPoints = (familyId) => {
    console.log(`Listening to family points for familyId: ${familyId}`)
    if (familyId) {
      const familyRef = doc(db, "Families", familyId)

      console.log(
        `Checking if user id ${authUser.uid} is in family ${familyId}`
      )

      const unsubscribe = onSnapshot(
        familyRef,
        (docSnapshot) => {
          if (docSnapshot.exists()) {
            const familyData = docSnapshot.data()
            console.log("received family data")
            console.log(familyData)
            setPoints(familyData.points || 0)
            setGames(familyData.games || [])

            setStoryId(familyData.currentStory)
          } else {
            console.log("Family not found")
          }
        },
        (err) => {
          console.log(`Encountered error: ${err}`)
        }
      )

      return () => unsubscribe()
    }
  }

  // add useEffect to listen to firestore

  useEffect(() => {
    const q = query(collection(db, "chores"))
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const items = []
      querySnapshot.forEach((doc) => {
        const choreData = doc.data()
        items.push({
          ...choreData,
          id: doc.id,
        })
      })

      setChores(items)
      setIsLoading(false)
    })

    return () => unsubscribe()
  }, [])

  const choresByAssignee = chores.reduce((acc, chore) => {
    acc[chore.assignee] = [...(acc[chore.assignee] || []), chore]
    return acc
  }, {})

  const handleDialogOpen = () => {
    setOpenDialog(true)
  }

  const handleOtherDialogOpen = (type) => {
    setOtherDialogType(type)
    setOpenOtherDialog(true)
  }

  const handleDialogClose = () => {
    setOpenDialog(false)
  }

  const addFamily = async () => {
    const payload = {
      familyName: "Dymock",
      points: 0,
      currentStory: "",
      members: [
        {
          name: "Ryan",
          role: "parent",
        },
        {
          name: "Leah",
          role: "parent",
        },
        {
          name: "Samantha",
          role: "child",
        },
        {
          name: "Connor",
          role: "child",
        },
        {
          name: "Marie",
          role: "child",
        },
      ],
    }

    const token = authUser.accessToken

    try {
      const response = await fetch(
        "https://on-add-family-blcpza7vla-uc.a.run.app",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify(payload),
        }
      )

      if (response.ok) {
        // Request was successful
        console.log("POST request successful")
        // You might want to do something with the response here if needed
      } else {
        // Request failed
        console.error("POST request failed")
      }
    } catch (error) {
      // Handle any network errors or other exceptions here
      console.error("Error:", error)
    }
  }

  const onAddPoints = async () => {
    const payload = {
      familyId: "t2VCwg2DVD42gFr7utiC",
      points: 4,
    }

    const token = authUser.accessToken

    try {
      const response = await fetch(
        "https://on-add-points-blcpza7vla-uc.a.run.app",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify(payload),
        }
      )

      if (response.ok) {
        // Request was successful
        console.log("POST request successful")
        // You might want to do something with the response here if needed
      } else {
        // Request failed
        console.error("POST request failed")
      }
    } catch (error) {
      // Handle any network errors or other exceptions here
      console.error("Error:", error)
    }
  }

  const onAddTestFamily = async () => {
    const payload = {}

    const url = "https://add-test-family-blcpza7vla-uc.a.run.app"

    const token = authUser.accessToken

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
          // Authorization: token,
        },
        body: JSON.stringify(payload),
      })

      if (response.ok) {
        // Request was successful
        console.log("POST request successful")
        // You might want to do something with the response here if needed
      } else {
        // Request failed
        console.error("POST request failed")
      }
    } catch (error) {
      // Handle any network errors or other exceptions here
      console.error("Error:", error)
    }
  }

  // const onButtonClick = async () => {
  //   const payload = {
  //     input_text: chapter1,
  //   }

  //   const token = authUser.accessToken

  //   try {
  //     const response = await fetch(
  //       "https://us-central1-photo-organization-1e84f.cloudfunctions.net/on_request_tts",
  //       {
  //         // const response = await fetch('http://127.0.0.1:5001/photo-organization-1e84f/us-central1/on_request_tts', {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: "Bearer " + token,
  //           // Authorization: token,
  //         },
  //         body: JSON.stringify(payload),
  //       }
  //     )

  //     if (response.ok) {
  //       // Request was successful
  //       console.log("POST request successful")
  //       // You might want to do something with the response here if needed
  //     } else {
  //       // Request failed
  //       console.error("POST request failed")
  //     }
  //   } catch (error) {
  //     // Handle any network errors or other exceptions here
  //     console.error("Error:", error)
  //   }
  // }

  return (
    <SiteWrapper>
      <div className="main-page-wrapper">
        <Helmet>
          <title>Chores</title>
        </Helmet>
        <Grid
          container
          spacing={3}
          justifyContent="space-between"
          style={{ padding: 5 }}
        >
          <Grid item container direction="row">
            <Typography variant="h6" align="center" component="h4">
              {points} Points
            </Typography>
          </Grid>
        </Grid>

        {/* <StoryView points={points} storyId={storyId}/> */}
        {/* <Button ref={targetRef} variant="contained" onClick={onButtonClick}>Test</Button>
        <br />
        <Button variant="contained" onClick={addFamily}>Add Family</Button>
        <br />
        <Button variant="contained" onClick={onAddTestFamily}>Add Test Family</Button>
        <br />
        <Button variant="contained" onClick={onAddPoints}>Add Points</Button>
        <br /> */}
        {/* {showGames && (
          <Grid container direction="row">
            {games.map((g) => (
              <Grid item xs={6} sm={4} md={2}>
                <GameIcons
                  status={g.status}
                  imageSrc={`${process.env.PUBLIC_URL}/images/${g.imageSrc}`}
                  points={g.points}
                  name={g.name}
                />
              </Grid>
            ))}
          </Grid>
        )} */}

        <DesktopChoresView choresByAssignee={choresByAssignee} />
        {isLoading && <LinearProgress sx={{ margin: "0px 50px" }} />}
      </div>
      {/* <Fab
        color="primary"
        aria-label="add"
        onClick={handleDialogOpen}
        style={{ position: "fixed", bottom: 20, right: 20 }}
      >
        <AddIcon />
      </Fab> */}
      <div
        style={{
          position: "fixed",
          bottom: 20,
          right: 20,
        }}
      >
        <AddSpeedDial
          onAddChore={handleDialogOpen}
          onAsNeeded={() => handleOtherDialogOpen("asNeeded")}
          onImprove={() => handleOtherDialogOpen("improvement")}
          onScreenList={() => handleOtherDialogOpen("screens")}
        />
      </div>
      <AddEditChoreDialog
        open={openDialog}
        handleClose={handleDialogClose}
        chore={initChore}
        setChore={setNewChore}
      />
      <AddOtherTasksDialog
        open={openOtherDialog}
        handleClose={() => setOpenOtherDialog(false)}
        type={otherDialogType}
        chore={initChore}
        setChore={setNewChore}
      />
      {/* <LottieAnimation targetRef={targetRef}/> */}
    </SiteWrapper>
  )
}

export default ChoresView;
