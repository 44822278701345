import { Box, Grid } from '@mui/material';
import React,{Fragment} from 'react';
import {Link} from 'react-router-dom';
import { Image } from '@mui/icons-material';


const FamTreeHeroBanner = () => {
    return (
        <Grid container 
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{width: "100vw", height: "340px", backgroundColor: "#5C0629", opacity: 0.75}}>
            <Grid item>
                <Link to="/" style={{textDecoration: "none", color: "white", opacity: 1}}>
                    <img src={`images/ftam/logo.jpg`} alt=""/>
                </Link>
            </Grid>
            

        </Grid>
    )
}

export default FamTreeHeroBanner