import {
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  FormControlLabel,
  DialogActions,
  Button,
  TextField,
  MenuItem,
  Checkbox,
} from "@mui/material"
import { addDoc, collection, doc, updateDoc } from "firebase/firestore"
import { db } from "../../../firebase/firebase"
import { assigneeOptions } from "./ChoresView"
import { useState } from "react"

const AddOtherTasksDialog = ({ open, handleClose, chore, type }) => {
  const [newDescription, setNewDescription] = useState(chore.description || "")
  const [newAssignee, setNewAssignee] = useState(chore.assignee)

  const updateTask = async () => {
    console.log("updating task")
    if (chore.id) {
      // Update existing chore

      console.log("new data")
      console.log(`${newDescription}, ${newAssignee}`)
      try {
        const choreRef = doc(db, "chores", chore.id)
        await updateDoc(choreRef, {
          description: newDescription,
          assignee: newAssignee,
          category: type,
          frequency: "daily", // all 'other' tasks are daily
        })
        handleClose()
      } catch (error) {
        console.error("Error updating chore: ", error)
      }
    } else {
      const choresToAdd = []

      console.log(newAssignee)

      // Determine the range of indices for assignees based on the condition
      const range =
        newAssignee === "All"
          ? [0, 5]
          : newAssignee === "All Kids"
            ? [2, 5]
            : null

      console.log(range)

      if (range) {
        assigneeOptions.slice(...range).forEach((assignee) => {
          choresToAdd.push({
            description: newDescription,
            assignee,
            frequency: "daily", // all 'other' tasks are daily
            category: type,
          })
        })
      } else {
        choresToAdd.push({
          description: newDescription,
          assignee: newAssignee,
          frequency: "daily", // all 'other' tasks are daily
          category: type,
        })
      }

      console.log(choresToAdd)
      try {
        await Promise.all(
          choresToAdd.map((chore) => addDoc(collection(db, "chores"), chore))
        )
        handleClose()
      } catch (error) {
        console.error("Error adding chore documents: ", error)
      }
    }
  }

  const handleAssigneeChange = (e) => {
    setNewAssignee(e.target.value)
  }

  const handleDescriptionChange = (e) => {
    setNewDescription(e.target.value)
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{chore.id ? "Edit Task" : "Add a New Task"}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="description"
          label="Task Description"
          type="text"
          fullWidth
          variant="standard"
          name="description"
          value={newDescription}
          onChange={handleDescriptionChange}
        />
        <FormControl fullWidth margin="dense">
          <InputLabel id="assignee-label">Assignee</InputLabel>
          <Select
            labelId="assignee-label"
            id="assignee"
            value={newAssignee}
            label="Assignee"
            onChange={handleAssigneeChange}
            name="assignee"
          >
            {assigneeOptions?.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={updateTask}>{chore.id ? "Save" : "Add"}</Button>
      </DialogActions>
    </Dialog>
  )
}

export default AddOtherTasksDialog
