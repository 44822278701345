import React, {Fragment} from 'react';
import { Link } from 'react-router-dom';
import { prettyPrint } from '../../views/inner-pages/blog/SiblingNewsletterPage';

const NewsLetterEntryNew = ({img, tag1, tag2, title, year, month, sibling, snippet}) => {
    const siblingLower = sibling ? sibling.toLowerCase() : "";

    return (
        <div
            className="col-lg-4 col-sm-6 d-flex"
            data-aos={'fade-up'}
            data-aos-delay={100}>
            <article className="blog-meta-one color-two tran3s mt-45">
                <figure className="post-img m0">
                    <Link to={`/newsletter/${year}/${month}/${siblingLower}`} className="w-100 d-block"><img src={img || "https://picsum.photos/200"} alt="" className="w-100 tran4s"/></Link>
                </figure>
                <div className="post-data">
                    <div className="post-tag">
                        <Link to={`/newsletter/${year}/${month}/${siblingLower}`}>{tag1}</Link>
                        {tag2}</div>
                    <Link to={`/newsletter/${year}/${month}/${siblingLower}`} className="blog-title">
                        <h4>{title}</h4>
                    </Link>
                    <p style={{fontSize: "80%"}}>{snippet}
                        <Link to={`/newsletter/${year}/${month}/${siblingLower}`} className="read-more">[READ MORE]</Link>
                    </p>
                </div>
            </article> 
    </div>
    )
}



const MonthNewslettersGrid = ({blogs}) => {
    return (
        <Fragment>
            <div className="row gx-xxl-5">
                {blogs.map((val, i)=> {
                    const snippet = val["text"][0] !== null ? val["text"][0].slice(0,130) + "..." : "";

                    return (
                    <NewsLetterEntryNew key={i} img={val["images"][0]}
                        tag1 = {val.tag1}
                        tag2 = {val.tag2}
                        title = {`${val.sibling}'s ${prettyPrint(val.month)} ${val.year} Newsletter`}
                        year = {val.year}
                        month = {val.month}
                        sibling = {val.sibling}
                        snippet={snippet}
                    />
                );
                })}
            </div>
        </Fragment>
    )
}

export default MonthNewslettersGrid