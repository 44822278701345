import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { firebaseConfig } from "../firebase/firebase.config"
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { getFirestore, doc, setDoc, collection, addDoc, getDoc, updateDoc, arrayUnion } from "firebase/firestore";
import { useEffect, useState } from "react";
import { GoogleAuthProvider } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";


export const Firebase = initializeApp(firebaseConfig);
export const auth = getAuth();
const provider = new GoogleAuthProvider();
// export const Providers = { google: new GoogleAuthProvider() };

export const db = getFirestore(Firebase);
export const storage = getStorage(Firebase);
// export const analytics = getAnalytics(Firebase);
export const functions = getFunctions(Firebase);

if (process.env.NODE_ENV !== 'production') {
  window.FIREBASE_APPCHECK_DEBUG_TOKEN = true
}

export const useFirebaseAuthentication = () => {
    const [authUser, setAuthUser] = useState(null);

    useEffect(() =>{
       const unlisten = auth.onAuthStateChanged(
          authUser => {
            authUser
              ? setAuthUser(authUser)
              : setAuthUser(null);
          },
       );
       return () => {
           unlisten();
      };
  }, []);

    return authUser
}

// const appCheck = initializeAppCheck(Firebase, {
//   provider: new ReCaptchaV3Provider('6LeiywUfAAAAAP6YybdwQmPF7N4Q8NBxtxsx_X4f'),
//   isTokenAutoRefreshEnabled: true
// });

// export function onAuthStateChange(callback) {
//   return auth.onAuthStateChanged(user => {
//     if (user) {
//       callback({loggedIn: true, email: user.email});
//     } else {
//       callback({loggedIn: false});
//     }
//   });
// }

export function login(username, password) {
  return new Promise((resolve, reject) => {
      signInWithEmailAndPassword(auth, username, password)
        .then(() => {
        return resolve()
      })
      .catch(error => {
        return reject(error)}
        );
  });
}

export function logout() {
  auth.signOut();
  localStorage.setItem("loggedIn", "FALSE");
}

/**
 * Initializes a new story in the Firestore database.
 * @param {string} title - The title of the story.
 * @param {string} description - A brief description of the story.
 * @return {Promise<string>} - The ID of the newly created story.
 */
export async function initializeNewStory(title, description) {
  try {
    // Create a new story document in the 'stories' collection
    const storyRef = await addDoc(collection(db, 'stories'), {
      title,
      description,
      createdAt: new Date()
    });

    console.log(`New story initialized with ID: ${storyRef.id}`);
    return storyRef.id;
  } catch (error) {
    console.error('Error initializing new story: ', error);
    throw error;
  }
}

/**
 * Adds a new part to a story document in Firestore.
 * @param {string} storyId - The ID of the story to update.
 * @param {string} newPartContent - The content of the new part to add.
 * @return {Promise<void>} - A promise that resolves when the update is complete.
 */
export async function addPartToStory(storyId, newPartContent) {
  try {
    const storyRef = doc(db, 'stories', storyId);

    // Check if the document already exists
    const docSnapshot = await getDoc(storyRef);

    if (docSnapshot.exists()) {
      // If the document exists, update the story by appending the new part
      await updateDoc(storyRef, {
        parts: arrayUnion(newPartContent)
      });

      console.log(`New part added to story ID: ${storyId}`);
    } else {
      // If the document doesn't exist, create it with the new part
      console.log('did not exist, adding');
      await setDoc(storyRef, {
        parts: [newPartContent]
      });

      console.log(`Story created with the new part. Story ID: ${storyId}`);
    }
  } catch (error) {
    console.error('Error adding new part to story: ', error);
    throw error;
  }
}


/**
 * Retrieves all parts of a story from Firestore.
 * @param {string} storyId - The ID of the story to retrieve.
 * @return {Promise<Array>} - A promise that resolves to an array of story parts.
 */
export async function getAllPartsOfStory(storyId) {
  try {
    const storyRef = doc(db, 'stories', storyId);
    const storySnap = await getDoc(storyRef);

    if (storySnap.exists()) {
      console.log('Story data:', storySnap.data());
      return storySnap.data().parts || [];
    } else {
      console.log('No such story!');
      return [];
    }
  } catch (error) {
    console.error('Error retrieving story: ', error);
    throw error;
  }
}

// // Example usage
// initializeNewStory('My New Story', 'This is a description of my new story.')
//   .then(storyId => console.log(`Story created with ID: ${storyId}`))
//   .catch(error => console.error(error));
