// src/components/BedtimeUtils.js
import React, { useState, useEffect } from "react"
import { getFirestore, doc, getDoc, updateDoc } from "firebase/firestore"
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage"
import {
  Button,
  List,
  ListItem,
  ListItemText,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  CircularProgress,
} from "@mui/material"
import { useSnackbar } from "notistack"
import PageWrapper from "../views/PageWrapper"

const BedtimeUtils = () => {
  const [files, setFiles] = useState([])
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [title, setTitle] = useState("")
  const [coverFile, setCoverFile] = useState(null)
  const [mp3File, setMp3File] = useState(null)

  const db = getFirestore()
  const storage = getStorage()

  const { showSnackbar } = useSnackbar()

  useEffect(() => {
    const fetchFiles = async () => {
      setLoading(true)
      const docRef = doc(db, "stories", "bedtime")
      const docSnap = await getDoc(docRef)
      if (docSnap.exists()) {
        const data = docSnap.data()
        setFiles(
          Object.entries(data).map(([key, value]) => ({ key, ...value }))
        )
      }
      setLoading(false)
    }
    fetchFiles()
  }, [db])

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setTitle("")
    setCoverFile(null)
    setMp3File(null)
  }

  const handleFileUpload = async () => {
    if (!title || !coverFile || !mp3File) {
      alert("All fields are required.")
      return
    }

    setLoading(true)

    const coverRef = ref(storage, `bedtime/${title}-cover.png`)
    const mp3Ref = ref(storage, `bedtime/${title}.mp3`)

    try {
      await uploadBytes(coverRef, coverFile)
      await uploadBytes(mp3Ref, mp3File)

      const coverUrl = await getDownloadURL(coverRef)
      const mp3Url = await getDownloadURL(mp3Ref)

      const docRef = doc(db, "stories", "bedtime")
      const docSnap = await getDoc(docRef)

      if (docSnap.exists()) {
        const data = docSnap.data()
        if (data[title]) {
          alert("Story with the same title already exists.")
          return
        } else {
          await updateDoc(docRef, {
            [title]: {
              title,
              coverUrl,
              mp3Url,
            },
          })
          showSnackbar("Story added successfully.")
        }
      }

      setFiles((prevFiles) => [...prevFiles, { title, coverUrl, mp3Url }])

      handleClose()
    } catch (error) {
      console.error("Error uploading files: ", error)
    }

    setLoading(false)
  }

  console.log("coverFile", coverFile)

  return (
    <PageWrapper>
      <h1>Bedtime Utils</h1>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        Add New Story
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add New Story</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To add a new story, please enter the title, select a cover image and
            an mp3 file.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Title"
            type="text"
            fullWidth
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <Button variant="outlined" component="label">
            {coverFile ? coverFile?.name : "Choose Cover Image"}

            <input
              type="file"
              accept="image/*"
              hidden
              id="cover-input"
              onChange={(e) => setCoverFile(e.target.files[0])}
              style={{ marginTop: "20px" }}
            />
          </Button>
          <br />
          <Button variant="outlined" component="label">
            {mp3File ? mp3File?.name : "Choose Audio File"}

            <input
              type="file"
              accept="audio/*"
              hidden
              id="audio-input"
              onChange={(e) => setMp3File(e.target.files[0])}
              style={{ marginTop: "20px" }}
            />
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleFileUpload} color="primary" disabled={loading}>
            {loading ? <CircularProgress /> : "Add Story"}
          </Button>
        </DialogActions>
      </Dialog>
      <List>
        {files.map((file, index) => (
          <ListItem key={index}>
            <ListItemText primary={file.title} secondary={file.mp3Url} />
          </ListItem>
        ))}
      </List>
    </PageWrapper>
  )
}

export default BedtimeUtils
