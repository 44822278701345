import React, {Fragment} from 'react';
import { Link } from 'react-router-dom';
import { db } from '../../firebase/firebase';
import { getDoc, doc } from 'firebase/firestore';

import { useEffect, useState } from 'react';
import { prettyPrint } from '../../views/inner-pages/blog/SiblingNewsletterPage';
import { Skeleton } from '@mui/material';

const NewsletterLinksContent = [
  {
    text: '2024',
    num: '(0)',
    routerPath: '/newsletter/2024',
    className: 'category' 
  },
    {
      text: '2023',
      num: '(13)', //(3) TODO
      routerPath: '/newsletter/2023',
      className: 'category' 
    },
    {
      text: '2022',
      num: '(30)', //(3) TODO
      routerPath: '/newsletter/2022',
      className: 'category' 
    },
    {
      text: '2021',
      num: '(20)', //TODO
      routerPath: '/newsletter/2021' ,
      className: 'category'
    },
    {
      text: '2020',
      num: '(11)', //TODO
      routerPath: '/newsletter/2020',
      className: 'category'
    }
]


export const DynamicNewsletterLinks = (props) => {

  if (Object.keys(props.data).length === 0) {
    return (
      <Fragment >
        <Skeleton width = {200}/>
        <Skeleton width = {200}/>
        <Skeleton width = {200}/>
      </Fragment>
    )
  }
  
  return (
  <Fragment>
    <ul className="style-none">
      {Object.keys(props.data).map((key, index)=>{
        const path = `/newsletter/${props.year}/${key.toLowerCase()}`;
        return (<li key={index} className="category">
            <Link to={path}>{`${prettyPrint(key)}`} <span style={{fontWeight: "bold"}}>{`(${props.data[key]})`}</span></Link>
        </li>
      )}
      )}
    </ul>
  </Fragment>
  );
}

export const useNewsLetterData = (year="2023") => {
    const [newsletterData, setNewsletterData] = useState({});
  
    useEffect(() => {
      let mounted = true;
      const getNewsletterData = async() => {

        let results = {};
        const docSnapshot = await getDoc(doc(db, "SiblingNewsletter", year));
        if (docSnapshot.exists() && mounted) {  
            setNewsletterData(docSnapshot.data());
        }
      };
      getNewsletterData();
      return () => mounted = false;
    }, []);
    return newsletterData;
  }

const NewsletterLinks = ({data}) => {
  
  return (
      <Fragment>
          <ul className="style-none">
            {NewsletterLinksContent.map((val, i)=>(
                  <li key={i} className={`${val.className}`}>
                  <Link to={val.routerPath}>{val.text} <span>{val.num}</span></Link>
              </li>
            ))}
          </ul>
      </Fragment>
  )
}

export default NewsletterLinks