import React, { useState, useEffect, useMemo } from "react"
import { collection, doc, getDoc, setDoc, onSnapshot } from "firebase/firestore"
import ExpensesTable from "./ExpensesTable"
import { startOfMonth, format } from "date-fns"
import { db } from "../../../firebase/firebase"
import PageWrapper from "../../PageWrapper"
import BudgetVsActualChart from "./BudgetVsActualChart"
import SpendingForecastChart from "./SpendingForecastChart"
import CategorySpendingPieChart from "./CategorySpendingPieChart"
import CumulativeSpendingChart from "./CumulativeSpendingChart"
import SpendingTrendsChart from "./SpendingTrends"

import {
  Tabs,
  Tab,
  Box,
  Grid,
  Typography,
  Slide,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material"
import { useNavigate } from "react-router-dom"

export const budget = [
  { category: "Costco", amount: 800 },
  { category: "Kroger", amount: 300 },
  { category: "Wants", amount: 300 },
  { category: "Needs", amount: 2000 },
  { category: "Other", amount: 100 },
  // More budget objects...
]

const ExpensesPage = () => {
  const [expenses, setExpenses] = useState([])
  const [tabIndex, setTabIndex] = useState(0)
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth())
  const [showSummaryDialog, setShowSummaryDialog] = useState(false)
  const navigate = useNavigate()

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue)
  }

  const currentMonth = useMemo(() => {
    const getCurrentMonth = (month) => {
      const currentDate = new Date()
      const updatedDate = new Date(currentDate.getFullYear(), month, 1) // Ensure valid date creation
      return format(startOfMonth(updatedDate), "yyyy-MM")
    }
    return getCurrentMonth(selectedMonth)
  }, [selectedMonth])

  useEffect(() => {
    const monthDocRef = doc(db, "expenses", currentMonth)

    // Real-time listener for the expenses document of the current month
    const unsubscribe = onSnapshot(
      monthDocRef,
      (snapshot) => {
        if (snapshot.exists()) {
          let expenses = snapshot.data().expenses || []
          setExpenses(expenses)
        } else {
          setExpenses([])
        }
      },
      (error) => {
        // Handle permissions error
        console.error("Error fetching expenses:", error)
        // Redirect to home page
        navigate("/")
      }
    )

    // Cleanup the listener on component unmount
    return () => unsubscribe()
  }, [currentMonth])

  const addOrUpdateExpense = async (expense) => {
    const monthDocRef = doc(db, "expenses", currentMonth)
    const monthDocSnapshot = await getDoc(monthDocRef)

    let updatedExpenses = []

    if (monthDocSnapshot.exists()) {
      const existingExpenses = monthDocSnapshot.data().expenses || []

      if (expense.id) {
        // Update an existing expense
        updatedExpenses = existingExpenses.map((exp) =>
          exp.id === expense.id ? expense : exp
        )
      } else {
        // Add a new expense
        expense.id = new Date().getTime().toString() // Simple unique ID
        updatedExpenses = [...existingExpenses, expense]
      }
    } else {
      // Add the first expense
      expense.id = new Date().getTime().toString() // Simple unique ID
      updatedExpenses = [expense]
    }

    await setDoc(monthDocRef, { expenses: updatedExpenses }, { merge: true })
    setShowSummaryDialog(true)
  }

  const deleteExpense = async (id) => {
    const monthDocRef = doc(db, "expenses", currentMonth)
    const monthDocSnapshot = await getDoc(monthDocRef)

    if (monthDocSnapshot.exists()) {
      const existingExpenses = monthDocSnapshot.data().expenses || []
      const updatedExpenses = existingExpenses.filter(
        (expense) => expense.id !== id
      )

      await setDoc(monthDocRef, { expenses: updatedExpenses }, { merge: true })
    }
  }

  const handleMonthChange = (event) => {
    console.log("Month changed", event.target.value)
    setSelectedMonth(event.target.value)
  }

  const [age, setAge] = React.useState("")

  const handleChange = (event) => {
    console.log("Age changed", event.target.value)
    setAge(event.target.value)
  }

  return (
    <PageWrapper>
      <Tabs
        value={tabIndex}
        onChange={handleTabChange}
        aria-label="Budget and Expenses Tabs"
        sx={{ marginBottom: 1 }}
      >
        <Tab label="Expenses" />
        <Tab label="Budget Charts" />
      </Tabs>
      <FormControl sx={{ m: 1, minWidth: 80 }}>
        <InputLabel id="month-select-label">Month</InputLabel>
        <Select
          labelId="month-select-label"
          id="month-select"
          value={selectedMonth}
          label="Month"
          onChange={handleMonthChange}
        >
          {Array.from({ length: 12 }, (v, i) => (
            <MenuItem key={i} onClick={() => console.log("click")} value={i}>
              {format(new Date(0, i), "MMMM")}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Box sx={{ position: "relative", height: "100%" }}>
        <Slide
          direction={"left"}
          in={tabIndex === 0}
          mountOnEnter
          unmountOnExit
        >
          <Box sx={{ position: "absolute", width: "100%" }}>
            <ExpensesTable
              expenses={expenses}
              onEdit={addOrUpdateExpense}
              onDelete={deleteExpense}
            />
          </Box>
        </Slide>

        <Slide
          direction={"right"}
          in={tabIndex === 1}
          mountOnEnter
          unmountOnExit
        >
          <Box sx={{ position: "absolute", width: "100%", padding: 2 }}>
            <Grid container spacing={4}>
              {/* Spending Forecast */}
              <Grid item xs={12} md={6}>
                <Typography variant="h6" gutterBottom>
                  Spending Forecast
                </Typography>
                <SpendingForecastChart expenses={expenses} budget={budget} />
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography variant="h6" gutterBottom>
                  Budget vs. Actual Spending
                </Typography>
                <BudgetVsActualChart expenses={expenses} budget={budget} />
              </Grid>

              {/* Category Spending Breakdown */}
              <Grid item xs={12} md={6}>
                <Typography variant="h6" gutterBottom>
                  Spending Breakdown by Category
                </Typography>
                <CategorySpendingPieChart expenses={expenses} />
              </Grid>

              {/* Spending Trends */}
              {/* <Grid item xs={6}>
                <Typography variant="h6" gutterBottom>
                  Spending Trends
                </Typography>
                <SpendingTrendsChart expenses={expenses} />
              </Grid> */}
            </Grid>
          </Box>
        </Slide>
      </Box>

      <Dialog
        open={showSummaryDialog}
        onClose={() => setShowSummaryDialog(false)}
      >
        <DialogTitle>Forecast</DialogTitle>
        <DialogContent>
          <SpendingForecastChart expenses={expenses} budget={budget} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowSummaryDialog(false)} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </PageWrapper>
  )
}

export default ExpensesPage
