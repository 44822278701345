import React from 'react';
import {Link} from 'react-router-dom'
import PageWrapper from "./PageWrapper"
import { Container } from "@mui/material"

const NotFound = () => {
  return (
    <PageWrapper>
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          textAlign: "center",
          marginTop: "40px",
        }}
      >
        {/* page requires sign in or does not exit */}
        <h3>Oops! You must be signed in to see this page.</h3>
        <Link to="/" className="btn-eight">
          Back to home
        </Link>
        <img src="images/assets/ils_21.svg" alt="" className="m-auto" />
        <img
          src="images/shape/shape_49.svg"
          alt=""
          className="shapes shape-one w-100"
        />
      </Container>
    </PageWrapper>
  )
}

export default NotFound