import React from "react"
import { Line } from "react-chartjs-2"
import { Box } from "@mui/material"
import { format, getDate, getDaysInMonth, isBefore, isSameDay } from "date-fns"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js"

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

const CumulativeSpendingChart = ({ expenses, budget }) => {
  const today = new Date()
  const daysInMonth = getDaysInMonth(today)

  const categories = [...new Set(expenses.map((exp) => exp.category))]
  const budgetedAmounts = categories.map(
    (category) => budget.find((b) => b.category === category)?.amount || 0
  )

  const totalBudget = budgetedAmounts.reduce((acc, curr) => acc + curr, 0)
  const dailyBudget = totalBudget / daysInMonth

  const actualSpending = Array.from({ length: daysInMonth }, (_, i) => {
    const date = new Date(today.getFullYear(), today.getMonth(), i + 1)
    if (isBefore(date, today) || isSameDay(date, today)) {
      return expenses
        .filter((exp) => getDate(new Date(exp.date)) === i + 1)
        .reduce((acc, curr) => acc + Number(curr.amount), 0)
    } else {
      return null // Use null or undefined to omit future data
    }
  })

  const cumulativeSpending = actualSpending
    .filter((value) => value !== null) // Filter out future values
    .map(
      (
        (sum) => (value) =>
          (sum += value)
      )(0)
    )

  const cumulativeBudget = Array.from(
    { length: daysInMonth },
    (_, i) => dailyBudget * (i + 1)
  )

  const data = {
    labels: Array.from({ length: daysInMonth }, (_, i) =>
      format(new Date(today.getFullYear(), today.getMonth(), i + 1), "MMM dd")
    ),
    datasets: [
      {
        label: "Cumulative Actual Spending",
        data: [
          ...cumulativeSpending,
          ...Array(daysInMonth - cumulativeSpending.length).fill(null),
        ],
        borderColor: "rgba(75,192,192,1)",
        backgroundColor: "rgba(75,192,192,0.2)",
        fill: true,
        spanGaps: false, // Ensure gaps are shown for future days
      },
      {
        label: "Cumulative Budget",
        data: cumulativeBudget,
        borderColor: "rgba(153,102,255,1)",
        backgroundColor: "rgba(153,102,255,0.2)",
        fill: true,
      },
    ],
  }

  const options = {
    responsive: true,
    plugins: { legend: { position: "top" } },
    scales: {
      x: {
        ticks: {
          callback: function (value, index) {
            return actualSpending[index] !== null
              ? this.getLabelForValue(value)
              : ""
          },
        },
      },
      y: {
        beginAtZero: true,
      },
    },
  }

  return (
    <Box sx={{ padding: 2 }}>
      <Line data={data} options={options} />
    </Box>
  )
}

export default CumulativeSpendingChart
