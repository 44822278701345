import React from 'react';
import {Helmet} from 'react-helmet';
import {Link} from 'react-router-dom';
import FamTreeAndMeNav from '../../components/header/FamTreeAndMeNav';
import CopyRightFour from '../../components/footer/CopyRightFour';
import FamTreeHeroBanner from '../../components/hero-banner/FamTreeHeroBanner';
import CarouselSliderBanner from '../../components/hero-banner/CarouselSliderBanner';
import { Grid } from '@mui/material';
import {HomePageCard} from '../../components/HomePageCard';
import FamTreeWelcomeBanner from '../../components/hero-banner/FamTreeWelcomeBanner';
import FamTreeButtonBanner from '../../components/hero-banner/FamTreeButtonBanner';
import FamTreeNewsletterBanner from '../../components/hero-banner/FamTreeNewsletterBanner';
import FamTreePromoBanner from '../../components/hero-banner/FamTreePromoBanner';
import FamTreeFooter from '../../components/hero-banner/FamTreeFooter';

const FtamHomePage = () => {

    return (
        <div className="main-page-wrapper" style={{
            backgroundImage: "url('https://media.rainpos.com/663/hex5f021f_burgundy_design_swirl_boldtiny09867633333333.jpg')",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "fixed",
            backgroundSize: "cover",
            height: "100vh"
        }}>
            <Helmet>
                <title>RyanDymock.com</title>
            </Helmet>
            <FamTreeAndMeNav/> 
            <FamTreeHeroBanner />
            <CarouselSliderBanner />

            <Grid 
                container
                direction="row"
                spacing={2}
                justifyContent="center"
                alignItems="center" 
                
                style={{}}
                >
                <Grid item style={{color: "white", margin: 0, padding: 0}}>
                    <HomePageCard 
                        title="Photo Family Trees"
                        description="Art designed to capture one's pedigree with head-and-shoulder photo shots of each family member."
                        link="/family-tree-and-me"
                        imgSrc="images/ftam/grove.jpg"
                    />
                </Grid>
                <Grid item style={{color: "white"}}>
                    <HomePageCard 
                        title="Family Proclamations"
                        description="Art featuring the document: The Family A Proclamation to the World with family photos around the perimeter."
                        link="/family-tree-and-me"
                        imgSrc="images/ftam/bott.jpg"
                    />
                </Grid>
                <Grid item style={{color: "white"}}>
                    <HomePageCard 
                        title="Missionary Photo Art"
                        description="Art highlighting the people, experiences, and memories from one's mission."
                        link="/family-tree-and-me"
                        imgSrc="images/ftam/mckinnon.jpg"
                    />
                </Grid>
                <Grid item style={{color: "white"}}>
                    <HomePageCard 
                        title="Generational Art"
                        description="Art that displays one's ancestral line in a Father-to-Son or Mother-to-Daughter photo format."
                        link="/family-tree-and-me"
                        imgSrc="images/ftam/generation.jpg"
                    />
                </Grid>
            </Grid>

            <FamTreeWelcomeBanner />

            <FamTreeButtonBanner />

            <FamTreeNewsletterBanner />

            <FamTreePromoBanner />

            <FamTreeFooter />

            {/* <div className="footer-style-two theme-basic-footer">
                <div className="container">
                  <div className="inner-wrapper">
                        <div className="bottom-footer">
                            <CopyRightFour />
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    )
}

export default FtamHomePage