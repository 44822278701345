import React, {Fragment} from 'react';
import {Helmet} from 'react-helmet';

import CopyRightFour from '../../../components/footer/CopyRightFour';
import { useParams } from 'react-router-dom';
import TopNavHome from '../../../components/header/TopNavHome';
import { useState, useEffect } from 'react';
import { getDoc, doc, addDoc, collection, getDocs, onSnapshot } from 'firebase/firestore';
import { db, useFirebaseAuthentication } from '../../../firebase/firebase';
import { Avatar, Dialog, DialogContent, IconButton, ListItem, ListItemAvatar, ListItemText, useMediaQuery, useTheme } from '@mui/material';
import { Close } from '@mui/icons-material';

import '@splidejs/react-splide/css/skyblue';
import { Splide, SplideSlide } from '@splidejs/react-splide';

import {Grid, Paper, Button, TextField, Box} from '@mui/material/';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

import NewsletterBreadCrumb from '../../../components/page-title/NewsletterBreadCrumb';
import SiteWrapper from "../../home-pages/SiteWrapper"

export const familyNames = {
    "ryan": "Ryan and Leah Dymock",
    "kellianne": "Kellianne and Keith Albretsen",
    "heidi": "Heidi and Zak Kandare",
    "amy": "Amy and Ethan Blanchard",
    "matthew": "Matthew and Sadie Dymock",
    "parentals": "Paul and Shirlene Dymock"
}

export const prettyPrint = (input) => {
    if (input === undefined) {
        return input;
    }
    input = input.toLowerCase();
    return input.charAt(0).toUpperCase() + input.slice(1);
}

const MasonryImages = ((props) => {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const isLarge = useMediaQuery(theme.breakpoints.up('lg'));
    const [selectedImage, setSelectedImage] = useState(null); // Used to determine which image (if any) is shown full-screen

    if (!props.images || props.images.length === 0) {
        return (<Fragment />)
    }

    let cols = isSmall ? 1 : 2;
    cols = isLarge ? 3 : cols;

    return (
        <Fragment>
            <ImageList variant="masonry" cols={cols} gap={8}>
                {props.images.map((item, idx) => (
                    <ImageListItem key={idx} onClick={() => setSelectedImage(item)}>
                        <img
                            src={`${item}?w=248&fit=crop&auto=format`}
                            srcSet={`${item}?w=248&fit=crop&auto=format&dpr=2 2x`}
                            alt={item.title}
                            loading="lazy"
                        />
                    </ImageListItem>
                ))}
            </ImageList>

            {/* Full-screen Image Dialog */}
            <Dialog open={selectedImage !== null} onClose={() => setSelectedImage(null)} fullWidth={true} maxWidth="md">
                <IconButton style={{ position: 'absolute', right: 0, top: 0, color: 'black' }} onClick={() => setSelectedImage(null)}>
                    <Close />
                </IconButton>
                <DialogContent>
                    <img src={selectedImage} alt="Full Size Preview" style={{ width: '100%', height: 'auto' }} />
                </DialogContent>
            </Dialog>
        </Fragment>
    )

});

// const SlideShow = ((props) => {
//     if (!props.images || props.images.length === 0) {
//         return (<Fragment />)
//     }

//     return (        
//         <Splide aria-label={props.title} 
//             options={{
//             } }>
//             {props.images.map((val, i)=>(
//                 <SplideSlide key={i}>
//                     <img src={val} alt={"Image " + i}/>
//                 </SplideSlide>
//             ))}
//         </Splide>
//     )
// });






const useBlogPostData = (year, month, sibling) => {
    const [blogData, setBlogData] = useState({ text: [], images: [], comments: [] });
    useEffect(() => {
      let mounted = true;
      const getBlogData = async () => {
        let results = {};
        const docRef = doc(db, "SiblingNewsletter", year, prettyPrint(month), prettyPrint(sibling));
        const docSnapshot = await getDoc(docRef);
    
        if (docSnapshot.exists() && mounted) {
            results = docSnapshot.data();
            
            // Correctly fetching comments
            const commentsRef = collection(docRef, 'comments');
            const commentsSnapshot = await getDocs(commentsRef);
            
            results.comments = commentsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setBlogData(results);
        }
    };
    
    
      getBlogData();
      return () => mounted = false;
    }, []);
    return blogData;
  }


  function timeAgo(timestamp) {
    const now = Date.now();
    const difference = now - timestamp;
    const minutes = Math.floor(difference / 60000);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (minutes < 60) {
        return `posted ${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
    } else if (hours < 24) {
        return `posted ${hours} hour${hours !== 1 ? 's' : ''} ago`;
    } else {
        return new Date(timestamp).toLocaleString();
    }
}



const SiblingNewsletterPage= (props) => {
    const { year, month, sibling } = useParams();

    const data = useBlogPostData(year, month, sibling);
    const [blogData, setBlogData] = useState({ text: [], images: [], comments: [] });
    const [comments, setComments] = useState([]);
    
    const authUser = useFirebaseAuthentication();
    console.log(authUser);

    useEffect(() => {
        const commentsRef = collection(db, "SiblingNewsletter", year, prettyPrint(month), prettyPrint(sibling), 'comments');
    
        const unsubscribe = onSnapshot(commentsRef, (querySnapshot) => {
            let newComments = [];
            querySnapshot.forEach((doc) => {
                newComments.push(doc.data());
            });
                    // Sort the comments by timestamp
            newComments.sort((a, b) => {
            if (a.timestamp < b.timestamp) {
                return -1;
            } else if (a.timestamp > b.timestamp) {
                return 1;
            } else {
                return 0;
            }
        });
            setComments(newComments);
        });
    
        // Cleanup: remove the listener when the component is unmounted
        return () => {
            unsubscribe();
        };
    }, [year, month, sibling]);
    

    console.log(blogData);

    let text = <p>No updates this month!  Check back with us later! </p>;

    if (data.text && data.text.length>0) {
        text = data.text.map((t,idx)=>{
            return (
                <p key={idx}>
                    {t}
                </p>
            );
        })
    }

    const addComment = async (text) => {
        if (!authUser) {
            //display error?
            return;
        }
        const newComment = {
            username: authUser.displayName,  // Use the logged-in user's name or ID
            text: text,
            timestamp: Date.now()
        };
    
        const commentRef = await addDoc(collection(db, "SiblingNewsletter", year, prettyPrint(month), prettyPrint(sibling), 'comments'), newComment);
        
        setBlogData(prevData => ({
            ...prevData,
            comments: [...prevData.comments, { id: commentRef.id, ...newComment }]
        }));
    };

    const CommentForm = ({ onAddComment }) => {
        const [text, setText] = useState('');
    
        const handleSubmit = (e) => {
            e.preventDefault();
            if (text.trim()) {
                onAddComment(text);
                setText('');
            }
        };
    
        return (
            <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <TextField
                    value={text}
                    onChange={e => setText(e.target.value)}
                    placeholder="Add a comment..."
                    multiline
                    rows={4}
                    variant="outlined"
                    fullWidth
                    style={{margin: 2}}
                />
                <Button variant="contained" color="primary" type="submit">
                    Post
                </Button>
            </Box>
        );
    };

    const CommentList = ({ comments }) => {
        return (
            <div className="comment-list">
                {comments.map(comment => (
                    <Paper style={{margin: 20, padding: 10}}>
                        <Grid container wrap="nowrap" spacing={2}>
                            <Grid item>
                            <Avatar alt={comment.username} src={""} >{comment.username ? comment.username[0].toUpperCase() : ""}</Avatar>
                            </Grid>
                            <Grid justifyContent="left" item xs zeroMinWidth>
                            <h4 style={{ margin: 0, textAlign: "left" }}>{comment.username}</h4>
                            <p style={{ textAlign: "left" }}>
                                {comment.text}{" "}
                            </p>
                            <small>{timeAgo(new Date(comment.timestamp).getTime())}</small>
                            </Grid>
                        </Grid>
                    </Paper>
                ))}
            </div>
        );
    };
 

    return (
      <SiteWrapper>
        <div className="main-page-wrapper">
          <Helmet>
            <title>
              {prettyPrint(month)} {year} - {familyNames[sibling]}
            </title>
          </Helmet>

          <div className="theme-inner-banner">
            <NewsletterBreadCrumb year={year} month={month} sibling={sibling} />
            {/* <img src="images/shape/shape_38.svg" alt="" className="shapes shape-one"/>
                    <img src="images/shape/shape_39.svg" alt="" className="shapes shape-two"/> */}
          </div>

          <div className="blog-details pt-90 mb-150 lg-pt-40 lg-mb-100">
            <div className="container">
              <div className="row">
                <div className="col-xxl-11 m-auto">
                  <div className="row">
                    <div className="col-lg-12">
                      {" "}
                      {/*Changed this from col-lg-8 when I commented out the newsletter links on the side */}
                      <article className="blog-meta-three tran3s mt-45">
                        <figure className="post-img m0">
                          <img
                            src="images/blog/blog_img_20.jpg"
                            alt=""
                            className="w-100 tran4s"
                          />
                        </figure>
                        <div className="post-data">
                          <div className="post-tag">
                            <a href="#">
                              {prettyPrint(month)} {year}
                            </a>
                          </div>
                          <div className="blog-title">
                            <h4>{familyNames[sibling]}</h4>
                          </div>
                          {text}
                          {/* <SlideShow title={familyNames[sibling]} images={data.images}/> */}
                          <MasonryImages
                            title={familyNames[sibling]}
                            images={data.images}
                          />

                          <div className="bottom-widget d-sm-flex align-items-center justify-content-between">
                            <ul className="d-flex tags style-none pt-10 p-2">
                              <li>Other Newsletters This Month:</li>
                              <li>
                                <a href={`/newsletter/${year}/${month}/ryan`}>
                                  Ryan
                                </a>
                                -
                              </li>
                              <li>
                                <a
                                  href={`/newsletter/${year}/${month}/kellianne`}
                                >
                                  Kellianne
                                </a>
                                -
                              </li>
                              <li>
                                <a href={`/newsletter/${year}/${month}/heidi`}>
                                  Heidi
                                </a>
                                -
                              </li>
                              <li>
                                <a href={`/newsletter/${year}/${month}/amy`}>
                                  Amy
                                </a>
                                -
                              </li>
                              <li>
                                <a
                                  href={`/newsletter/${year}/${month}/matthew`}
                                >
                                  Matthew
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                        {/* /.post-data */}
                      </article>
                      <CommentList comments={comments} />
                      <CommentForm onAddComment={addComment} />
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="blog-sidebar ps-xl-5 ps-lg-3 me-xxl-5 mt-45 md-mt-70"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="footer-style-four space-fix-one theme-basic-footer">
            <div className="container">
              <div className="inner-wrapper">
                {/* <FooterTwo/> */}
                <div className="bottom-footer">
                  <CopyRightFour />
                </div>
              </div>
              {/* /.inner-wrapper */}
            </div>
          </div>
          {/* /.footer-style-four */}
        </div>
      </SiteWrapper>
    )
}

export default SiblingNewsletterPage