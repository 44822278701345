import { Stack, Grid, Divider, Link, Typography, Box } from '@mui/material';
import React,{Fragment} from 'react';
import { Image } from '@mui/icons-material';

const categories = ["Photo Family", "FAMILY TREES", "PROCLAMATIONS", "MISSIONARIES", "GENERATIONAL ART", "CONTACT US"];

const contactArray = [
    {
      title: "Home",
      width: 75,
      link: "/"
    },
]

const shopArray = [
    {
        title: "Photo Family Trees",
        link: "/"
    },
    {
        title: "Family Proclamations",
        link: "/"
    },
    {
        title: "Missionary Photo Art",
        link: "/"
    },
    {
        title: "Generational Art",
        link: "/"
    },
    {
        title: "Gift Certificiates",
        link: "/"
    },
];

const informationArray = [
    {
        title: "Photographs",
        link: "/"
    },
    {
        title: "Scanning",
        link: "/"
    },
    {
        title: "Policies",
        link: "/"
    },
    {
        title: "Price List",
        link: "/"
    },
    {
        title: "FAQ",
        link: "/"
    },
];

const ListItem = ({textColor, text, isUnderlined}) => {
    if (isUnderlined) {
        return (
            <Typography variant="p" style={{color: textColor, margin: 0, textDecoration: "underline", fontSize: "16px"}}>{text}</Typography>
        )
    }
    return (
        <Typography variant="p" style={{color: textColor, margin: 0, fontSize: "16px"}}>{text}</Typography>
    )};



const FamTreeFooter = () => {
    return (
        <Stack style={{padding: "20px", backgroundColor: "black"}}>
        <Grid container direction="row" justifyContent="center" alignItems="flex-start" style={{width: "100vw", padding: 20}}>
            <Grid container 
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={0}
            xs={3}
            style={{}}>
            <Grid item style={{lineHeight: "30px"}}>
                    <ListItem text={"CONTACT US"} textColor="white" isUnderlined={true}/>
                    {/* <Typography variant="p" style={{color: "white", textDecoration: "underline", fontSize: "16px"}}>CONTACT US</Typography> */}
                </Grid>
                    <Grid item style={{lineHeight: "30px"}}>
                    <ListItem text={"Family Tree & Me"} textColor="#c0b283" isUnderlined={false}/>
                </Grid>
                    <Grid item style={{lineHeight: "30px"}}>
                    <Link href="tel:8014005802">
                        <ListItem text={"801-400-5802"} textColor="#c0b283" isUnderlined={false}/>
                    </Link>
                </Grid>
                    <Grid item style={{lineHeight: "45px"}}>
                    <Link href="mailto:shirlenedymock@familytreeandme.com">
                        <ListItem text={"shirlenedymock@familytreeandme.com"} textColor="#c0b283" isUnderlined={false}/>
                    </Link>
                </Grid>
            </Grid>
            <Grid container
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={0}
                xs={3}
                style={{}}>
                <Grid item style={{margin: 0}}>
                    <Typography variant="p" style={{color: "white", textDecoration: "underline", fontSize:"16px"}}>SHOP BY CATEGORY</Typography>
                </Grid>
                 {shopArray.map((entry) => (
                    <Grid item style={{lineHeight: "30px"}}>
                        <Link href={entry.link} style={{color: "gold", fontSize: "16px"}}>
                            <Typography variant="p" style={{color: "#c0b283"}}>{entry.title}</Typography>
                        </Link>
                    </Grid>
                    ))}
            </Grid>
            <Grid container
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={0}
                xs={3}
                style={{}}>
                <Grid item style={{margin: 0}}> 
                    <Typography variant="p" style={{color: "white", textDecoration: "underline", fontSize:"16px"}}>INFORMATION</Typography>
                </Grid>
                 {informationArray.map((entry) => (
                    <Grid item style={{lineHeight: "30px"}}>
                        <Link href={entry.link} style={{color: "gold", fontSize:"16px", lineHeight: "20px"}}>
                            <Typography variant="p" style={{color: "#c0b283"}}>{entry.title}</Typography>
                        </Link>
                    </Grid>
                    ))}
            </Grid>
            <Grid container
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={0}
                xs={3}
                style={{}}>
                <Grid item style={{margin: 0}}> 
                    <Typography variant="p" style={{color: "white", textDecoration: "underline", fontSize:"16px"}}>PAYMENT METHODS</Typography>
                </Grid>
                <Grid item>
                    <img src="/images/ftam/paypal_logo.jpg" alt="visa" style={{width: "262px", height: "131px"}}/>
                </Grid>

            </Grid>
        </Grid>
        <Divider />
        <Box style={{backgroundColor: "black", width: "100vw", display: "flex", justifyContent: "center", alignItems: "center", paddingTop: "50px", paddingBottom: "50px"}}>
            <Stack direction="column">
                <Stack direction="row" spacing={2}>
                    <Typography variant="p" style={{color: "white", fontSize: "12px", textAlign: "center", lineHeight: "20px"}}>
                        <a href='/'>Home</a>&emsp;|&emsp;
                        <a href='/purchase'>Purchase</a>&emsp;|&emsp; 
                        <a href='/photo-family-trees'>Photo Family Trees</a>&emsp;|&emsp;
                        <a href='/family-proclamations'>Family Proclamations</a>&emsp;|&emsp;
                        <a href='/missionary-photo-art'>Missionary Photo Art</a>&emsp;|&emsp;
                        <a href='/generational-art'>Generational Art</a>&emsp;|&emsp;
                        <a href='/contact-us'>Contact Us</a>&emsp;|&emsp;
                        <a href='/site-map'>Site Map</a>
                    </Typography>
                </Stack>
                <br />
                <Typography variant="p" style={{color: "white", fontSize: "14px", textAlign: "center", lineHeight: "20px"}}>Copyright © 2007-2023 - Family Tree & Me</Typography>
                <Typography variant="p" style={{color: "white", fontSize: "14px", textAlign: "center", lineHeight: "20px"}}>Website design by RCD</Typography>
            </Stack>
        </Box>
        </Stack>
    )
}

export default FamTreeFooter