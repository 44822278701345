import { FormControl, Grid, InputLabel, MenuItem, Select, useMediaQuery } from "@mui/material";
import { assignees } from "./ChoresView";
import AssigneeChoresSection from "./AssigneeChoresSection";
import { useState } from "react";

const sectionColors = {
  daily: "lightgreen",
  weekly: "lightblue",
  monthly: "#DDA0DD",
  improvement: "#F08080",
  screens: "#FFDB58", //FAFAD2
  asNeeded: "#778899",
}

const ChoresTabPanel = ({ frequency, choresByAssignee, setAssignee, assignee }) => {
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

    const handleAssigneeChange = (e) => {
        setAssignee(e.target.value);
    }

    const assigneeDropdown = () => {
        return (
        <Grid item xs={12} sx={{ textAlign: "center", fontSize: "1.5rem", fontWeight: "bold", mb: 2 }}>
            <FormControl fullWidth margin="dense">
            <InputLabel id="assignee-label">Assignee</InputLabel>
            <Select
                labelId="assignee-label"
                id="assignee"
                value={assignee}
                label="Assignee"
                onChange={handleAssigneeChange}
                name="assignee"
            >
                {assignees.map(option => (
                <MenuItem key={option} value={option}>{option}</MenuItem>
                ))}
            </Select>
            </FormControl>
        </Grid>
        )
    }

    const color = sectionColors[frequency] || "white";
    return (
        <Grid container justifyContent={"center"} sx = {{ backgroundColor: color}}>
            {isMobile && assigneeDropdown()}
            {!isMobile && assignees.map((assignee) => {
                return (
                <AssigneeChoresSection key={assignee} chores={choresByAssignee[assignee]} assignee={assignee} label={frequency} />
                );
            })}
            {isMobile && <AssigneeChoresSection key={assignee} chores={choresByAssignee[assignee]} assignee={assignee} label={frequency} />}
        </Grid>
    );
}

export default ChoresTabPanel;