import { Button, Grid, TextField, Typography } from '@mui/material';
import React,{Fragment} from 'react';
import {Link} from 'react-router-dom';
import { Image } from '@mui/icons-material';
import styled from "styled-components";
// import { withStyles } from '@mui/styles';

// const WhiteBorderTextField = styled({
//   root: {
//     "& label.Mui-focused": {
//       color: "white",
//     },
//     "& .MuiInput-underline:after": {
//       borderBottomColor: "white",
//       color: "white",
//     },
//     "& .MuiOutlinedInput-root": {
//       "& fieldset": {
//         borderColor: "white",
//         color: "white",
//       },
//       "&:hover fieldset": {
//         borderColor: "white",
//         color: "white",
//       },
//       "&.Mui-focused fieldset": {
//         borderColor: "white",
//         color: "white",
//       },
//     },
//   },
// })(TextField)


const FamTreeNewsletterBanner = () => {
    return (
      <Grid
        container
        spacing={3}
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{ width: "100vw", height: "88px", backgroundColor: "#5C0629" }}
      >
        <Grid item>
          <Typography variant="h6" style={{ color: "#be8d4b" }}>
            NEWSLETTER SIGNUP
          </Typography>
          <Typography variant="h7" style={{ color: "white" }}>
            Sign up to receive promo’s via email
          </Typography>
        </Grid>
        <Grid item style={{ padding: 10 }}>
          {/* <WhiteBorderTextField 
                id="outlined-basic" 
                label="Email Address" 
                variant="outlined" 
                InputLabelProps={{ style: { color: "white" } }} 
                inputProps={{ style: { color: "white" } }} 
                style={{width: "400px", color: "white"}}/> */}
        </Grid>
        <Grid item style={{ padding: 10 }}>
          <Button
            variant="outlined"
            style={{ color: "white", borderColor: "white" }}
          >
            SUBSCRIBE
          </Button>
        </Grid>
      </Grid>
    )
}

export default FamTreeNewsletterBanner