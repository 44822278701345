import React, {Fragment, useState, useEffect} from 'react';
import {Helmet} from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';

import CopyRightFour from "../../../components/footer/CopyRightFour"

import {
  getDoc,
  doc,
  collection,
  query,
  getDocs,
  where,
} from "firebase/firestore"
import { db } from "../../../firebase/firebase"
import MonthNewslettersGrid from "../../../components/blog/MonthNewslettersGrid"
import NewsletterBreadCrumb from "../../../components/page-title/NewsletterBreadCrumb"
import NewsletterLinks from "../../../components/blog/NewsletterLinks"
import SiteWrapper from "../../home-pages/SiteWrapper"

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "Aaugust",
  "September",
  "October",
  "November",
  "December",
]

const prettyPrint = (input) => {
  if (input === undefined) {
    return input
  }
  input = input.toLowerCase()
  return input.charAt(0).toUpperCase() + input.slice(1)
}

// Not functional yet
const Pagination = ({ numPages }) => (
  <div className="page-pagination-one pt-90">
    <ul className="d-flex align-items-center justify-content-center style-none">
      <li className="active">
        <a href="#">1</a>
      </li>
      <li>
        <a href="#">2</a>
      </li>
      <li>
        <a href="#">3</a>
      </li>
      <li className="arrow">
        <a href="#">
          <i className="bi bi-arrow-right" />
        </a>
      </li>
    </ul>
  </div>
)

const useYearlyData = (year) => {
  const [blogData, setBlogData] = useState([])
  const navigate = useNavigate()

  useEffect(() => {
    let mounted = true
    const getBlogData = async () => {
      let newsletters = []

      try {
        if (year !== undefined) {
          for (const m of months) {
            const q = query(collection(db, "SiblingNewsletter", year, m))
            const querySnapshot = await getDocs(q)
            querySnapshot.forEach((doc) => {
              if (doc.data()["text"].length > 0) {
                let data = doc.data()
                data["month"] = m.toLowerCase()
                data["year"] = year
                data["sibling"] = doc.id
                newsletters.push(data)
              }
            })
          }
        }
        setBlogData(newsletters)
      } catch (error) {
        if (error.code === "permission-denied") {
          navigate("/signin")
        }
      }
    }
    getBlogData()
    return () => (mounted = false)
  }, [])
  return blogData
}

const useMonthlyData = (year, month) => {
  const [blogData, setBlogData] = useState([])
  const navigate = useNavigate()

  useEffect(() => {
    let mounted = true
    const getBlogData = async () => {
      let results = {}
      if (month !== undefined && year !== undefined) {
        const q = query(
          collection(db, "SiblingNewsletter", year, prettyPrint(month))
        )
        try {
          const querySnapshot = await getDocs(q)
          const newsletters = []
          querySnapshot.forEach((doc) => {
            if (doc.data()["text"].length > 0) {
              let data = doc.data()
              data["month"] = month
              data["year"] = year
              data["sibling"] = doc.id
              newsletters.push(data)
            }
          })
          if (newsletters.length > 0 && mounted) {
            setBlogData(newsletters)
          }
        } catch (error) {
          if (error.code === "permission-denied") {
            navigate("/signin")
          }
        }
      }
    }
    getBlogData()
    return () => (mounted = false)
  }, [])
  return blogData
}

const MonthlyNewslettersPage = () => {
  const { year, month } = useParams()
  const monthlyData = useMonthlyData(year, month)
  const yearlyData = useYearlyData(year)

  const newsletterData = month === undefined ? yearlyData : monthlyData

  const title = month === undefined ? year : prettyPrint(month) + " " + year

  if (year === undefined && month === undefined) {
    return (
      <SiteWrapper>
        <div className="main-page-wrapper">
          <Helmet>
            <title>{title}</title>
          </Helmet>

          <div className="theme-inner-banner">
            <NewsletterBreadCrumb year={year} month={month} />
            <img
              src="images/shape/shape_38.svg"
              alt=""
              className="shapes shape-one"
            />
            <img
              src="images/shape/shape_39.svg"
              alt=""
              className="shapes shape-two"
            />
          </div>

          <div className="blog-details pt-90 mb-150 lg-pt-40 lg-mb-100">
            <div className="container">
              <div className="row">
                <div className="col-xxl-11 m-auto">
                  <div className="row">
                    <div className="col-lg-8"></div>
                    <div className="col-lg-4 col-md-6">
                      <div className="blog-sidebar ps-xl-5 ps-lg-3 me-xxl-5 mt-45 md-mt-70">
                        <div className="sidebar-category mb-50">
                          <h5 className="sidebar-title">Archive</h5>
                          <NewsletterLinks />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="footer-style-four space-fix-one theme-basic-footer">
            <div className="container">
              <div className="inner-wrapper">
                <div className="bottom-footer">
                  <CopyRightFour />
                </div>
              </div>
            </div>
          </div>
        </div>
      </SiteWrapper>
    )
  }
  return (
    <SiteWrapper>
      <div className="main-page-wrapper">
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <div className="theme-inner-banner">
          <NewsletterBreadCrumb year={year} month={month} />
          <img
            src="images/shape/shape_38.svg"
            alt=""
            className="shapes shape-one"
          />
          <img
            src="images/shape/shape_39.svg"
            alt=""
            className="shapes shape-two"
          />
        </div>
        {/* /.theme-inner-banner */}
        <div className="blog-section-three pt-90 mb-150 lg-pt-40 lg-mb-100">
          <div className="container">
            <MonthNewslettersGrid blogs={newsletterData} />
            {/* <Pagination /> */}
          </div>
        </div>
        <div className="footer-style-four space-fix-one theme-basic-footer">
          <div className="container">
            <div className="inner-wrapper">
              <div className="bottom-footer">
                <CopyRightFour />
              </div>
            </div>
          </div>
        </div>
      </div>
    </SiteWrapper>
  )
}

export default MonthlyNewslettersPage