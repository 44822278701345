import React, {Fragment, useState} from 'react';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import {Link} from 'react-router-dom';
// import Lightbox from 'react-image-lightbox';
// import 'react-image-lightbox/style.css';
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"

import { TabListContent, PortfolioContent, Development, 
    Plugin, Design, MobileApp, Branding, images} from './PortfolioData';
    
const PortfolioGalleryFive = () => {

    const [isOpen,
        setIsOpen] = useState(false);
    const [photoIndex,
        setPhotoIndex] = useState(0);

    return (
      <Fragment>
        {/* {!!isOpen && (<Lightbox
                        mainSrc={images[photoIndex]}
                        nextSrc={images[(photoIndex + 1) % images.length]}
                        prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                        onCloseRequest={() => setIsOpen(false)}
                        onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
                        onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}/>)} */}
        <Tabs>
          <TabList className="style-none text-center isotop-menu-wrapper g-control-nav-one pb-30 lg-pb-10">
            {TabListContent.map((tab, i) => (
              <Tab key={i}>{tab}</Tab>
            ))}
          </TabList>

          <TabPanel>
            <div
              id="isotop-gallery-wrapper"
              class="grid-2column custom-container"
            >
              <ResponsiveMasonry columnsCountBreakPoints={{ 575: 1, 576: 2 }}>
                <Masonry>
                  {PortfolioContent.map((val, i) => (
                    <div
                      key={i}
                      className="isotop-item"
                      data-aos={val.fade}
                      data-aos-delay={val.dataDelay}
                    >
                      <div className="portfolio-block-two mt-25">
                        <div className="img-meta">
                          <img
                            src={`images/gallery/${val.img}.jpg`}
                            alt=""
                            className="w-100"
                          />
                          <div className="hover-state tran3s">
                            <a
                              className="fancybox tran3s"
                              data-fancybox
                              title="Click for large view"
                              href="#"
                              onClick={() => setIsOpen(!isOpen)}
                              tabIndex={0}
                            >
                              <i className={val.plus} />
                            </a>
                          </div>
                        </div>
                        <Link
                          to="/portfolio-details"
                          className="title tran3s d-flex flex-column justify-content-center align-items-center"
                        >
                          <span className="tag">{val.tag}</span>
                          <span className="pj-name">{val.pjname}</span>
                        </Link>
                      </div>
                      {/* /.portfolio-block-two */}
                    </div>
                  ))}
                </Masonry>
              </ResponsiveMasonry>
            </div>
          </TabPanel>
          <TabPanel>
            <div
              id="isotop-gallery-wrapper"
              class="grid-2column custom-container"
            >
              <ResponsiveMasonry columnsCountBreakPoints={{ 575: 1, 576: 2 }}>
                <Masonry>
                  {Development.map((val, i) => (
                    <div
                      key={i}
                      className="isotop-item col-md-6"
                      data-aos={val.fade}
                      data-aos-delay={val.dataDelay}
                    >
                      <div className="portfolio-block-two mt-25">
                        <div className="img-meta">
                          <img
                            src={`images/gallery/${val.img}.jpg`}
                            alt=""
                            className="w-100"
                          />
                          <div className="hover-state tran3s">
                            <a
                              className="fancybox tran3s"
                              data-fancybox
                              title="Click for large view"
                              href="#"
                              onClick={() => setIsOpen(!isOpen)}
                              tabIndex={0}
                            >
                              <i className={val.plus} />
                            </a>
                          </div>
                        </div>
                        <Link
                          to="/portfolio-details"
                          className="title tran3s d-flex flex-column justify-content-center align-items-center"
                        >
                          <span className="tag">{val.tag}</span>
                          <span className="pj-name">{val.pjname}</span>
                        </Link>
                      </div>
                      {/* /.portfolio-block-two */}
                    </div>
                  ))}
                </Masonry>
              </ResponsiveMasonry>
            </div>
          </TabPanel>
          <TabPanel>
            <div
              id="isotop-gallery-wrapper"
              class="grid-2column custom-container"
            >
              <ResponsiveMasonry columnsCountBreakPoints={{ 575: 1, 576: 2 }}>
                <Masonry>
                  {Plugin.map((val, i) => (
                    <div
                      key={i}
                      className="isotop-item"
                      data-aos={val.fade}
                      data-aos-delay={val.dataDelay}
                    >
                      <div className="portfolio-block-two mt-25">
                        <div className="img-meta">
                          <img
                            src={`images/gallery/${val.img}.jpg`}
                            alt=""
                            className="w-100"
                          />
                          <div className="hover-state tran3s">
                            <a
                              className="fancybox tran3s"
                              title="Click for large view"
                              href="#"
                              onClick={() => setIsOpen(!isOpen)}
                            >
                              <i className={val.plus} />
                            </a>
                          </div>
                        </div>
                        <Link
                          to="/portfolio-details"
                          className="title tran3s d-flex flex-column justify-content-center align-items-center"
                        >
                          <span className="tag">{val.tag}</span>
                          <span className="pj-name">{val.pjname}</span>
                        </Link>
                      </div>
                      {/* /.portfolio-block-two */}
                    </div>
                  ))}
                </Masonry>
              </ResponsiveMasonry>
            </div>
          </TabPanel>
          <TabPanel>
            <div
              id="isotop-gallery-wrapper"
              class="grid-2column custom-container"
            >
              <ResponsiveMasonry columnsCountBreakPoints={{ 575: 1, 576: 2 }}>
                <Masonry>
                  {Design.map((val, i) => (
                    <div
                      key={i}
                      className="isotop-item"
                      data-aos={val.fade}
                      data-aos-delay={val.dataDelay}
                    >
                      <div className="portfolio-block-two mt-25">
                        <div className="img-meta">
                          <img
                            src={`images/gallery/${val.img}.jpg`}
                            alt=""
                            className="w-100"
                          />
                          <div className="hover-state tran3s">
                            <a
                              className="fancybox tran3s"
                              data-fancybox
                              title="Click for large view"
                              href="#"
                              onClick={() => setIsOpen(!isOpen)}
                              tabIndex={0}
                            >
                              <i className={val.plus} />
                            </a>
                          </div>
                        </div>
                        <Link
                          to="/portfolio-details"
                          className="title tran3s d-flex flex-column justify-content-center align-items-center"
                        >
                          <span className="tag">{val.tag}</span>
                          <span className="pj-name">{val.pjname}</span>
                        </Link>
                      </div>
                      {/* /.portfolio-block-two */}
                    </div>
                  ))}
                </Masonry>
              </ResponsiveMasonry>
            </div>
          </TabPanel>
          <TabPanel>
            <div
              id="isotop-gallery-wrapper"
              class="grid-2column custom-container"
            >
              <ResponsiveMasonry columnsCountBreakPoints={{ 575: 1, 576: 2 }}>
                <Masonry>
                  {MobileApp.map((val, i) => (
                    <div
                      key={i}
                      className="isotop-item"
                      data-aos={val.fade}
                      data-aos-delay={val.dataDelay}
                    >
                      <div className="portfolio-block-two mt-25">
                        <div className="img-meta">
                          <img
                            src={`images/gallery/${val.img}.jpg`}
                            alt=""
                            className="w-100"
                          />
                          <div className="hover-state tran3s">
                            <a
                              className="fancybox tran3s"
                              data-fancybox
                              title="Click for large view"
                              href="#"
                              onClick={() => setIsOpen(!isOpen)}
                              tabIndex={0}
                            >
                              <i className={val.plus} />
                            </a>
                          </div>
                        </div>
                        <Link
                          to="/portfolio-details"
                          className="title tran3s d-flex flex-column justify-content-center align-items-center"
                        >
                          <span className="tag">{val.tag}</span>
                          <span className="pj-name">{val.pjname}</span>
                        </Link>
                      </div>
                      {/* /.portfolio-block-two */}
                    </div>
                  ))}
                </Masonry>
              </ResponsiveMasonry>
            </div>
          </TabPanel>
          <TabPanel>
            <div
              id="isotop-gallery-wrapper"
              class="grid-2column custom-container"
            >
              <ResponsiveMasonry columnsCountBreakPoints={{ 575: 1, 576: 2 }}>
                <Masonry>
                  {Branding.map((val, i) => (
                    <div
                      key={i}
                      className="isotop-item"
                      data-aos={val.fade}
                      data-aos-delay={val.dataDelay}
                    >
                      <div className="portfolio-block-two mt-25">
                        <div className="img-meta">
                          <img
                            src={`images/gallery/${val.img}.jpg`}
                            alt=""
                            className="w-100"
                          />
                          <div className="hover-state tran3s">
                            <a
                              className="fancybox tran3s"
                              data-fancybox
                              title="Click for large view"
                              href="#"
                              onClick={() => setIsOpen(!isOpen)}
                              tabIndex={0}
                            >
                              <i className={val.plus} />
                            </a>
                          </div>
                        </div>
                        <Link
                          to="/portfolio-details"
                          className="title tran3s d-flex flex-column justify-content-center align-items-center"
                        >
                          <span className="tag">{val.tag}</span>
                          <span className="pj-name">{val.pjname}</span>
                        </Link>
                      </div>
                      {/* /.portfolio-block-two */}
                    </div>
                  ))}
                </Masonry>
              </ResponsiveMasonry>
            </div>
          </TabPanel>
        </Tabs>
      </Fragment>
    )
}

export default PortfolioGalleryFive
