import React,{Fragment, useState} from 'react';
import { Link } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { signOut } from 'firebase/auth';
import { Avatar, Grid, Grow, Paper, Popper, MenuItem, MenuList, ClickAwayListener, Container } from '@mui/material';
import { useFirebaseAuthentication } from '../../firebase/firebase';

// const useStyles = {
//     root: {
//       display: 'flex',
//       margin: 0,
//       padding: 0,
//     },
//     avatarGrid: {
//     margin: 0,
//     padding: 0,
//     },
//   };

const menuArray = [
  {
    title: "Home",
    width: 75,
    link: "/"
  },
  {
    title: "Purchase",
    width: 100,
    // link: "/shop.htm"
    link: "/"
  },
  {
    title: "Photo Family Trees",
    width: 175,
    // link: "/photo-family-trees.htm",
    link: "/"
  },
  {
    title: "Family Proclamations",
    width: 200,
    // link: "/family-proclamations.htm"
    link: "/"
  },
  {
    title: "Missionary Photo Art",
    width: 200,
    // link: "/missionary-photo-art.htm"
    link: "/"
  },
  {
    title: "Generational Art",
    width: 160,
    link: "/"
    // link: "/generational-art.htm"
  },
  {
    title: "Contact Us",
    width: 125,
    // link: "/contact-us.htm"
    link: "/"
  },
  {
    title: "Newsletters",
    width: 200,
    // link: "/contact-us.htm"
    link: "/newsletter"
  },

]

const MenuItemWithHistory = (props) => {
    const handleClick = (event) => {
    }
    return (
      <MenuItem onClick={handleClick}>My Page</MenuItem>
    );
  }

const FamTreeAndMeNav = () => {
    const [navbar, setNavbar] = useState(false);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [open, setOpen] = useState(false);
    const [anchorElement, setAnchorElement] = useState(null);  

    const user = useFirebaseAuthentication();

    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const openModal = () => {
        setIsOpen(true);
    }
    const closeModal = () => {
        setIsOpen(!modalIsOpen);
    }

  const toggleMenu =()=>{
    if(window.scrollY >= 68) {
      setNavbar(true);
    } else{
      setNavbar(false);
    }
  }

  const handleToggle = (event) => {
    setOpen(!open);
    setAnchorElement(event.currentTarget);
  }

  const login = () => {
    navigate("/signIn");
  }

  const logout = (event) => {
    setOpen(false);
    let self = this;

    const auth = getAuth();
    signOut(auth).then(() => {
      enqueueSnackbar('You have been logged out', {
          variant: 'success',
          anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
          },
      });
      localStorage.setItem("loggedIn", "FALSE");
      navigate("/signin");
    }).catch((error) => {
      // An error happened.
    });
  }

  let popperMenu = (
    <MenuList autoFocusItem={open}  id="menu-list-grow" onKeyDown={()=>console.log('on key down')}>
      <MenuItem onClick={login}>Login</MenuItem>
    </MenuList>
  );

  let avatar = (<Avatar alt={"login"} onClick={handleToggle} />);
  if (user) {
    avatar = (
      <Avatar
        alt={user.displayName}
        src={user.avatar || user.photoURL}
        onClick={handleToggle} />
    );
    popperMenu = (
      <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={()=>console.log('handleListKeyDown')}>
        <MenuItem onClick={logout}>Logout</MenuItem>
      </MenuList>
    );
  }

  window.addEventListener('scroll', toggleMenu);

  return (
    <Fragment>
      <Grid 
        container
        direction="row"
        justifyContent="center"
        alignItems="center" 
        hidden={{xs: true, sm: true, md: false, lg: false, xl: false}}
        sx={{backgroundColor: "#5C0629", width: "100%", height: 50}}>

        {menuArray.map(i=>
          <Link to={i.link}>
            <Grid item sx={{color: "white", width: i.width, margin: "0 10px 0 10px"}}>
            {i.title}
          </Grid> 
          </Link> 
        )}      
      </Grid>
         
    </Fragment>
  )
}

export default FamTreeAndMeNav