import React, { useState, useEffect } from 'react';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { useTheme } from '@mui/material/styles';
import { Grid, Card, CardMedia, CardActionArea, CardContent, Typography, Box, IconButton, Avatar } from '@mui/material';
import TopNavHome from '../../../components/header/TopNavHome';
import ReactAudioPlayer from 'react-audio-player';

const BedtimeStoriesView = () => {
  const [stories, setStories] = useState([]);
  const [currentUrl, setCurrentUrl] = useState('');
  const [currentIndex, setCurrentIndex] = useState(null);
  const theme = useTheme();

  useEffect(() => {
    const fetchStories = async () => {
      const db = getFirestore();
      const docRef = doc(db, 'stories', 'bedtime');
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        const storiesList = Object.values(data);
        // sort storiesList
        const sortedStoriesList = storiesList.sort((a, b) => {
          return a.title.localeCompare(b.title);
        });
        setStories(sortedStoriesList);
      } else {
        console.log('No such document!');
      }
    };
    fetchStories();
  }, []);

  const handleCardClick = (story, index) => {
    setCurrentUrl(story.mp3Url);
    setCurrentIndex(index);
  };

  return (
    <div style={{ maxWidth: 900, marginLeft: 'auto', marginRight: 'auto' }} className="main-page-wrapper">
      <TopNavHome />
      <br />
      <br />
      <br />

      <Grid container spacing={3}>
        {stories.map((story, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card onClick={() => handleCardClick(story, index)}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="280"
                  image={story.coverUrl || `https://via.placeholder.com/250x300`}
                  alt={story.title}
                  onError={(e) => e.target.src = `https://via.placeholder.com/250x300`}
                  sx={{ objectFit: 'contain' }}
                />
                <CardContent>
                  {/* <Typography gutterBottom variant="subtitle2" component="div">
                    // {story.title}
                  </Typography> */}
                </CardContent>
              </CardActionArea>
              {currentIndex === index && (
                <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
                  <ReactAudioPlayer
                    src={currentUrl}
                    controls
                    autoPlay
                    style={{ width: '100%', marginLeft: '16px' }}
                  />
                </Box>
              )}
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default BedtimeStoriesView;