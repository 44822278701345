import React from "react"
import { Pie } from "react-chartjs-2"
import { Box } from "@mui/material"
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js"

ChartJS.register(ArcElement, Tooltip, Legend)

const CategorySpendingPieChart = ({ expenses }) => {
  const categories = [...new Set(expenses.map((exp) => exp.category))]
  const totalSpending = categories.map((category) =>
    expenses
      .filter((exp) => exp.category === category)
      .reduce((acc, curr) => acc + Number(curr.amount), 0)
  )

  const data = {
    labels: categories,
    datasets: [
      {
        label: "Monthly Total",
        data: totalSpending,
        backgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#9966FF",
          "#FF9F40",
        ],
      },
    ],
  }

  return (
    <Box sx={{ padding: 2 }}>
      <Pie
        data={data}
        options={{ responsive: true, plugins: { legend: { position: "top" } } }}
      />
    </Box>
  )
}

export default CategorySpendingPieChart
