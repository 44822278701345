import React from "react"
import { Bar } from "react-chartjs-2"
import { Box } from "@mui/material"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js"

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const BudgetVsActualChart = ({ expenses, budget }) => {
  // Ensure all categories are represented in the chart
  const categories = budget.map((item) => item.category)

  // Calculate actual spending for each category, ensuring zero if no spending
  const actualSpending = categories.map((category) =>
    expenses
      .filter((exp) => exp.category === category)
      .reduce((acc, curr) => acc + Number(curr.amount), 0)
  )

  // Get the budgeted amounts for each category
  const budgetedAmounts = categories.map(
    (category) => budget.find((b) => b.category === category)?.amount || 0
  )

  const data = {
    labels: categories,
    datasets: [
      {
        label: "Actual Spending",
        data: actualSpending,
        backgroundColor: "rgba(75,192,192,0.6)",
      },
      {
        label: "Budgeted Amount",
        data: budgetedAmounts,
        backgroundColor: "rgba(153,102,255,0.6)",
      },
    ],
  }

  return (
    <Box sx={{ padding: 2, minHeight: 350 }}>
      <Bar
        data={data}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          plugins: { legend: { position: "top" } },
        }}
      />
    </Box>
  )
}

export default BudgetVsActualChart
