import React, {Fragment, useState} from 'react';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import {Link} from 'react-router-dom';
// import Lightbox from 'react-image-lightbox';
// import 'react-image-lightbox/style.css';

import { TabListContent, PortfolioContent, Development, 
    Plugin, Design, MobileApp, Branding, images} from './PortfolioData';

const PortfolioGalleryTwo = () => {

    const [isOpen,
        setIsOpen] = useState(false);
    const [photoIndex,
        setPhotoIndex] = useState(0);

    return (
      <Fragment>
        {/* {!!isOpen && (<Lightbox
                        mainSrc={images[photoIndex]}
                        nextSrc={images[(photoIndex + 1) % images.length]}
                        prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                        onCloseRequest={() => setIsOpen(false)}
                        onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
                        onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}/>)} */}
        <Tabs>
          <TabList className="style-none text-center isotop-menu-wrapper g-control-nav-one pb-30 lg-pb-10">
            {TabListContent.map((tab, i) => (
              <Tab key={i}>{tab}</Tab>
            ))}
          </TabList>

          <TabPanel>
            <div className="row gx-xxl-5">
              {PortfolioContent.map((val, i) => (
                <div
                  key={i}
                  className="col-lg-4 col-sm-6"
                  data-aos={val.fade}
                  data-aos-delay={val.dataDelay}
                >
                  <div className="portfolio-block-one mt-40 xs-mt-30">
                    <div className="img-meta">
                      <img
                        src={`images/gallery/${val.img}.jpg`}
                        alt=""
                        className="w-100"
                      />
                    </div>
                    <Link
                      to="/portfolio-details"
                      className="title tran3s d-flex flex-column justify-content-center align-items-center"
                    >
                      <span className="tag">{val.tag}</span>
                      <span className="pj-name">{val.pjname}</span>
                    </Link>
                    <div className="hover-state tran3s">
                      <a
                        href="#"
                        onClick={() => setIsOpen(!isOpen)}
                        className="fancybox tran3s"
                        title="Click for large view"
                      >
                        <i className={val.plus} />
                      </a>
                    </div>
                  </div>
                  {/* /.portfolio-block-one */}
                </div>
              ))}
            </div>
          </TabPanel>
          <TabPanel>
            <div className="row gx-xxl-5">
              {Development.map((val, i) => (
                <div
                  key={i}
                  className="col-lg-4 col-sm-6"
                  data-aos={val.fade}
                  data-aos-delay={val.dataDelay}
                >
                  <div className="portfolio-block-one mt-40 xs-mt-30">
                    <div className="img-meta">
                      <img
                        src={`images/gallery/${val.img}.jpg`}
                        alt=""
                        className="w-100"
                      />
                    </div>
                    <Link
                      to="/portfolio-details"
                      className="title tran3s d-flex flex-column justify-content-center align-items-center"
                    >
                      <span className="tag">{val.tag}</span>
                      <span className="pj-name">{val.pjname}</span>
                    </Link>
                    <div className="hover-state tran3s">
                      <a
                        href="#"
                        onClick={() => setIsOpen(!isOpen)}
                        className="fancybox tran3s"
                        title="Click for large view"
                      >
                        <i className={val.plus} />
                      </a>
                    </div>
                  </div>
                  {/* /.portfolio-block-one */}
                </div>
              ))}
            </div>
          </TabPanel>
          <TabPanel>
            <div className="row gx-xxl-5">
              {Plugin.map((val, i) => (
                <div
                  key={i}
                  className="col-lg-4 col-sm-6"
                  data-aos={val.fade}
                  data-aos-delay={val.dataDelay}
                >
                  <div className="portfolio-block-one mt-40 xs-mt-30">
                    <div className="img-meta">
                      <img
                        src={`images/gallery/${val.img}.jpg`}
                        alt=""
                        className="w-100"
                      />
                    </div>
                    <Link
                      to="/portfolio-details"
                      className="title tran3s d-flex flex-column justify-content-center align-items-center"
                    >
                      <span className="tag">{val.tag}</span>
                      <span className="pj-name">{val.pjname}</span>
                    </Link>
                    <div className="hover-state tran3s">
                      <a
                        href="#"
                        onClick={() => setIsOpen(!isOpen)}
                        className="fancybox tran3s"
                        title="Click for large view"
                      >
                        <i className={val.plus} />
                      </a>
                    </div>
                  </div>
                  {/* /.portfolio-block-one */}
                </div>
              ))}
            </div>
          </TabPanel>
          <TabPanel>
            <div className="row gx-xxl-5">
              {Design.map((val, i) => (
                <div
                  key={i}
                  className="col-lg-4 col-sm-6"
                  data-aos={val.fade}
                  data-aos-delay={val.dataDelay}
                >
                  <div className="portfolio-block-one mt-40 xs-mt-30">
                    <div className="img-meta">
                      <img
                        src={`images/gallery/${val.img}.jpg`}
                        alt=""
                        className="w-100"
                      />
                    </div>
                    <Link
                      to="/portfolio-details"
                      className="title tran3s d-flex flex-column justify-content-center align-items-center"
                    >
                      <span className="tag">{val.tag}</span>
                      <span className="pj-name">{val.pjname}</span>
                    </Link>
                    <div className="hover-state tran3s">
                      <a
                        href="#"
                        onClick={() => setIsOpen(!isOpen)}
                        className="fancybox tran3s"
                        title="Click for large view"
                      >
                        <i className={val.plus} />
                      </a>
                    </div>
                  </div>
                  {/* /.portfolio-block-one */}
                </div>
              ))}
            </div>
          </TabPanel>
          <TabPanel>
            <div className="row gx-xxl-5">
              {MobileApp.map((val, i) => (
                <div
                  key={i}
                  className="col-lg-4 col-sm-6"
                  data-aos={val.fade}
                  data-aos-delay={val.dataDelay}
                >
                  <div className="portfolio-block-one mt-40 xs-mt-30">
                    <div className="img-meta">
                      <img
                        src={`images/gallery/${val.img}.jpg`}
                        alt=""
                        className="w-100"
                      />
                    </div>
                    <Link
                      to="/portfolio-details"
                      className="title tran3s d-flex flex-column justify-content-center align-items-center"
                    >
                      <span className="tag">{val.tag}</span>
                      <span className="pj-name">{val.pjname}</span>
                    </Link>
                    <div className="hover-state tran3s">
                      <a
                        href="#"
                        onClick={() => setIsOpen(!isOpen)}
                        className="fancybox tran3s"
                        title="Click for large view"
                      >
                        <i className={val.plus} />
                      </a>
                    </div>
                  </div>
                  {/* /.portfolio-block-one */}
                </div>
              ))}
            </div>
          </TabPanel>
          <TabPanel>
            <div className="row gx-xxl-5">
              {Branding.map((val, i) => (
                <div
                  key={i}
                  className="col-lg-4 col-sm-6"
                  data-aos={val.fade}
                  data-aos-delay={val.dataDelay}
                >
                  <div className="portfolio-block-one mt-40 xs-mt-30">
                    <div className="img-meta">
                      <img
                        src={`images/gallery/${val.img}.jpg`}
                        alt=""
                        className="w-100"
                      />
                    </div>
                    <Link
                      to="/portfolio-details"
                      className="title tran3s d-flex flex-column justify-content-center align-items-center"
                    >
                      <span className="tag">{val.tag}</span>
                      <span className="pj-name">{val.pjname}</span>
                    </Link>
                    <div className="hover-state tran3s">
                      <a
                        href="#"
                        onClick={() => setIsOpen(!isOpen)}
                        className="fancybox tran3s"
                        title="Click for large view"
                      >
                        <i className={val.plus} />
                      </a>
                    </div>
                  </div>
                  {/* /.portfolio-block-one */}
                </div>
              ))}
            </div>
          </TabPanel>
        </Tabs>
      </Fragment>
    )
}

export default PortfolioGalleryTwo