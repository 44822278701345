import React, { useState } from "react"
import { motion } from "framer-motion"

const AnimationDemo = () => {
  const [isToggled, setIsToggled] = useState(false)

  // Variants for animation states
  const boxVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { opacity: 1, scale: 1 },
  }

  const toggleVariants = {
    off: { scale: 1, rotate: 0 },
    on: { scale: 1.2, rotate: 180 },
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "20px",
        marginTop: "50px",
      }}
    >
      {/* Basic Animation: Fade-in and Scale */}
      <motion.div
        initial="hidden"
        animate="visible"
        variants={boxVariants}
        transition={{ duration: 0.5 }}
        style={{
          width: "150px",
          height: "150px",
          backgroundColor: "#61dafb",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "24px",
          borderRadius: "10px",
        }}
      >
        Basic
      </motion.div>

      {/* Gesture: Hover and Tap */}
      <motion.div
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        style={{
          width: "150px",
          height: "150px",
          backgroundColor: "#ffcc00",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "24px",
          borderRadius: "10px",
        }}
      >
        Gesture
      </motion.div>

      {/* Variants: Toggle Animation */}
      <motion.div
        variants={toggleVariants}
        animate={isToggled ? "on" : "off"}
        transition={{ type: "spring", stiffness: 500, damping: 30 }}
        onClick={() => setIsToggled(!isToggled)}
        style={{
          width: "150px",
          height: "150px",
          backgroundColor: "#00cc66",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "24px",
          borderRadius: "50%",
          cursor: "pointer",
        }}
      >
        Toggle
      </motion.div>

      {/* Layout Animation: Layout Change */}
      <motion.div
        layout
        style={{
          display: "flex",
          flexDirection: isToggled ? "column" : "row",
          gap: "10px",
          marginTop: "30px",
        }}
      >
        <motion.div
          layout
          style={{
            width: isToggled ? "100px" : "50px",
            height: "50px",
            backgroundColor: "#9933ff",
            borderRadius: "5px",
          }}
        />
        <motion.div
          layout
          style={{
            width: isToggled ? "100px" : "50px",
            height: "50px",
            backgroundColor: "#ff3333",
            borderRadius: "5px",
          }}
        />
        <motion.div
          layout
          style={{
            width: isToggled ? "100px" : "50px",
            height: "50px",
            backgroundColor: "#33ccff",
            borderRadius: "5px",
          }}
        />
      </motion.div>
    </div>
  )
}

export default AnimationDemo
