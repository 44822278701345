import React, { useState } from "react"
import { Button } from "@mui/material"
import PageWrapper from "../../PageWrapper"
import Confetti from "react-confetti"

const ConfettiTest = () => {
  const [confettiConfig, setConfettiConfig] = useState(null)

  const handleClick = (event) => {
    const x = event.clientX
    const y = event.clientY

    setConfettiConfig({
      x,
      y,
      width: 20,
      height: 20,
      duration: 3000, // Duration in milliseconds
    })

    // Clear the confetti after the duration
    setTimeout(() => setConfettiConfig(null), 3000)
  }

  return (
    <PageWrapper>
      <div
        onClick={handleClick}
        style={{
          width: "100vw",
          height: "100vh",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <Button>Click anywhere to animate!</Button>

        {confettiConfig && (
          <Confetti
            width={window.innerWidth}
            height={window.innerHeight}
            numberOfPieces={40}
            recycle={false}
            confettiSource={{
              x: confettiConfig.x - confettiConfig.width / 2,
              y: confettiConfig.y - confettiConfig.height / 2,
              w: confettiConfig.width,
              h: confettiConfig.height,
            }}
          />
        )}
      </div>
    </PageWrapper>
  )
}

export default ConfettiTest
