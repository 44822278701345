// documentation here: https://www.hiv.uw.edu/page/mental-health-screening/gad-7

export const gad7Questions = [
    {
      id: 0,
      text: 'Feeling nervous, anxious or on edge',
      type: 'likert_scale',
      options: [
        { label: 'Not at all', value: 0 },
        { label: 'Several days', value: 1 },
        { label: 'More than half the days', value: 2 },
        { label: 'Nearly every day', value: 3 }
      ]
    },
    {
      id: 1,
      text: 'Not being able to stop or control worrying',
      type: 'likert_scale',
      options: [
        { label: 'Not at all', value: 0 },
        { label: 'Several days', value: 1 },
        { label: 'More than half the days', value: 2 },
        { label: 'Nearly every day', value: 3 }
      ]
    },
    {
      id: 2,
      text: 'Worrying too much about different things',
      type: 'likert_scale',
      options: [
        { label: 'Not at all', value: 0 },
        { label: 'Several days', value: 1 },
        { label: 'More than half the days', value: 2 },
        { label: 'Nearly every day', value: 3 }
      ]
    },
    {
      id: 3,
      text: 'Trouble relaxing',
      type: 'likert_scale',
      options: [
        { label: 'Not at all', value: 0 },
        { label: 'Several days', value: 1 },
        { label: 'More than half the days', value: 2 },
        { label: 'Nearly every day', value: 3 }
      ]
    },
    {
      id: 4,
      text: 'Being so restless that it is hard to sit still',
      type: 'likert_scale',
      options: [
        { label: 'Not at all', value: 0 },
        { label: 'Several days', value: 1 },
        { label: 'More than half the days', value: 2 },
        { label: 'Nearly every day', value: 3 }
      ]
    },
    {
      id: 5,
      text: 'Becoming easily annoyed or irritable',
      type: 'likert_scale',
      options: [
        { label: 'Not at all', value: 0 },
        { label: 'Several days', value: 1 },
        { label: 'More than half the days', value: 2 },
        { label: 'Nearly every day', value: 3 }
      ]
    },
    {
      id: 6,
      text: 'Feeling afraid as if something awful might happen',
      type: 'likert_scale',
      options: [
        { label: 'Not at all', value: 0 },
        { label: 'Several days', value: 1 },
        { label: 'More than half the days', value: 2 },
        { label: 'Nearly every day', value: 3 }
      ]
    }
  ];
  
export const anxietyScoring = [
    { min: 0, max: 4, result: 'Minimal Anxiety' },
    { min: 5, max: 9, result: 'Mild Anxiety' },
    { min: 10, max: 14, result: 'Moderate Anxiety' },
    { min: 15, max: Infinity, result: 'Severe Anxiety' }
  ];
  