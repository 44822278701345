import React, { Fragment, useEffect } from "react";
import { Helmet } from "react-helmet";
import AOS from 'aos';
import "aos/dist/aos.css"
import { BrowserRouter } from 'react-router-dom';
import AppRouter from "./router/AppRouter";
import ScrollToTop from "./components/ScrollToTop";
import { SnackbarProvider } from "notistack";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Provider } from "react-redux";
import store from "./redux/store";

const theme = createTheme({
});

function App() {

  useEffect(() => {
    document.title = 'Ryan Dymock website';
  }, []);

  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);

  return (
    <Fragment>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="keywords" content="Data Science, Analytics, Data, sass, software company" />
        <meta name="description"
          content="ryandymock.com" />
        <meta property="og:site_name" content="RyanDymock" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Ryan Dymock personal website" />
      </Helmet>
      {/* {End Seo Helmet}
       */}
      <ScrollToTop />
      <BrowserRouter>
      <Provider store={store}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
          }}>
          <AppRouter />
        </SnackbarProvider>
        </ThemeProvider>
        </Provider>
      </BrowserRouter>
    </Fragment>

  );
}

export default App;
