import React, {Fragment} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { Avatar } from '@mui/material';
import { logout } from '../../firebase/firebase';
import { useSnackbar } from 'notistack';
import { blue } from '@mui/material/colors';
import { useFirebaseAuthentication } from '../../firebase/firebase';

const currentDate = new Date();
currentDate.setMonth(currentDate.getMonth() - 1);

const year = String(currentDate.getFullYear());
const month = currentDate.toLocaleString('default', { month: 'long' });


const currentNewsLetterPath = `/newsletter/${year}/${month}`;

// const currentNewsLetterPath = "/newsletter/2022/november";

const HomeMenu = [
    {
        name: 'User Analysis',
        routerPath: '/'
    }, {
        name: 'Artificial Intelligence',
        routerPath: '/artificial-intelligence'
    }, {
        name: 'Data Science',
        routerPath: '/data-science'
    }, {
        name: 'ChatBoot',
        routerPath: '/chatboot'
    }, {
        name: 'Machine Learning',
        routerPath: '/machine-learning'
    }
];

const AboutMenu = [
    {
        name: 'About Us One',
        routerPath: '/about-one'
    }, {
        name: 'About Us Two',
        routerPath: '/about-two'
    }
];

const ServiceMenu = [
    {
        name: 'Service One',
        routerPath: '/service-one'
    }, {
        name: 'Service Two',
        routerPath: '/service-two'
    }, {
        name: 'Service Details',
        routerPath: '/service-details'
    }
];

const TeamMenu = [
    {
        name: 'Team Member',
        routerPath: '/team-member'
    }, {
        name: 'Team Details',
        routerPath: '/team-details'
    }
];

const PortfolioMenu = [
    {
        name: 'Portfolio 3 Column',
        routerPath: '/portfolio-3'
    }, {
        name: 'Portfolio 2 Column',
        routerPath: '/portfolio-2'
    }, {
        name: 'Portfolio Masonry',
        routerPath: '/portfolio-masonry'
    }, {
        name: 'Single Portfolio',
        routerPath: '/portfolio-single'
    }
];

const BlogMenu = [
    {
        name: 'Grid Layout',
        routerPath: '/blog-grid'
    }, {
        name: 'Grid With Sidebar',
        routerPath: '/blog-sidebar'
    }, {
        name: 'Blog Masonry',
        routerPath: '/blog-masonry'
    }, {
        name: 'Blog Standard',
        routerPath: '/blog-standard'
    }, {
        name: 'Blog Details',
        routerPath: '/blog-Details'
    }
];


const DymockFamilyMenu = [
  // {
  //     name: 'Picture Sorting',
  //     routerPath: '/picture-sorting'
  // },
  // {
  //     name: 'Photo Journal',
  //     routerPath: '/photo-journal'
  // },
  {
    name: "Addresses",
    routerPath: "/addresses",
  },
  {
    name: "Bedtime Stories",
    routerPath: "/bedtime-stories",
  },
  // {
  //     name: 'Come Follow Me',
  //     routerPath: '/come-follow-me'
  // },
  {
    name: "Chores",
    routerPath: "/chores",
  },
  // {
  //     name: 'Podcast',
  //     routerPath: '/podcast'
  // },
  {
    name: "Growing Up Pictures",
    routerPath: "/viewPictures",
  },
  {
    name: "Dad Retirement",
    routerPath: "/retirement",
  },
  {
    name: "Sibling Newsletter",
    routerPath: currentNewsLetterPath,
  },
  // {
  //     name: 'Anxiety Screening',
  //     routerPath: '/leah'
  // }
]

const Miscellaneous = [
    {
        name: 'Testimonials',
        routerPath: '/testimonial'
    }, {
        name: 'Our Pricing',
        routerPath: '/price'
    }, {
        name: 'FAQ',
        routerPath: '/faq'
    }, {
        name: '404 Error',
        routerPath: '/error'
    }, 
];

const LoggedOutAvatarListItem = () => {
    return (
        <li className='nav-item dropdown'>
            <a className="nav-link dropdown-toggle" href="#" role="button">
                <Avatar /> 
                </a>                 
            <ul className="dropdown-menu">
                <li>
                    <Link to={"/signin"} className="dropdown-item">
                        <span>Login</span>
                    </Link>
                </li>

            </ul>
        </li>
    )
}
const LoggedInAvatarListItem = (props) => {
    const initial =  props.user.displayName ? props.user.displayName.charAt(0) : "U";
    return (
        <li className='nav-item dropdown'>
            <a className="nav-link dropdown-toggle" href="#" role="button">
                <Avatar sx={{ bgcolor: blue[500] }}>{initial}</Avatar> 
                </a>                 
            <ul className="dropdown-menu">
                <li>
                    <span className="dropdown-item" onClick={props.onLogout}>Logout</span>
                </li>

            </ul>
        </li>
    )
}

const ThemeMainMenu = (props) => {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const user = useFirebaseAuthentication();

    const onLogout = () => {
        logout();
        enqueueSnackbar('You have been logged out', {
            variant: 'success',
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
            },
        });
        navigate("/");
    }

    return (
      <Fragment>
        <ul className="navbar-nav">
          <li className="d-block d-lg-none">
            <div className="logo">
              <Link to="/">
                <img src="images/logo/logo_01.png" alt="" width={130} />
              </Link>
            </div>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/" role="button">
              Home
            </Link>
          </li>
          <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle" href="#" role="button">
              Dymock Family
            </a>
            <ul className="dropdown-menu">
              {DymockFamilyMenu.map((val, i) => (
                <li key={i}>
                  <Link to={val.routerPath} className="dropdown-item">
                    <span>{val.name}</span>
                  </Link>
                </li>
              ))}
            </ul>
          </li>
          {!user && <LoggedOutAvatarListItem />}
          {user && <LoggedInAvatarListItem user={user} onLogout={onLogout} />}
        </ul>
      </Fragment>
    )
}
export default ThemeMainMenu