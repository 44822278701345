import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@mui/material';

function AddOrEditDialog({ open, handleClose, handleSave, initialData, allData }) {
  const [data, setData] = useState({ ...initialData });

  useEffect(() => {
    setData({ ...initialData });
  }, [initialData]);

  const handleChange = (e) => {
    console.log(e.target.name, e.target.value);
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleFormSubmit = () => {
    if (data.id) {
      // Edit existing entry
      const updatedData = allData.map(item => item.id === data.id ? data : item);
      handleSave(updatedData);
    } else {
      // Add new entry
      const newData = [...allData, { ...data, id: Date.now().toString() }]; // Assign a new ID
      handleSave(newData);
    }
    handleClose();
  };

  console.log(data);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{data.address ? 'Edit Address' : 'Add Address'}</DialogTitle>

      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          name="name"
          label="Name"
          type="text"
          fullWidth
          value={data.name || ''}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          name="address"
          label="Address 1"
          type="text"
          fullWidth
          value={data.address || ''}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          name="address2"
          label="Address 2"
          type="text"
          fullWidth
          value={data.address2 || ''}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          name="city"
          label="City"
          type="text"
          fullWidth
          value={data.city || ''}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          name="state"
          label="State"
          type="text"
          fullWidth
          value={data.state || ''}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          name="zip"
          label="Zip Code"
          type="text"
          fullWidth
          value={data.zip || ''}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleFormSubmit}>Submit</Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddOrEditDialog;
