import { useState } from "react";
import { useEffect } from "react";
import { Gallery } from "react-grid-gallery";
// import Lightbox from "react-image-lightbox";
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import DownloadIcon from '@mui/icons-material/Download';
import { IconButton } from "@mui/material";

const PictureGrid = ({ pictures }) => {
    const [visiblePictures, setVisiblePictures] = useState([]); // Subset of visible images
    const [loadIndex, setLoadIndex] = useState(0); // Index to track loading position
    const [index, setIndex] = useState(-1);
    console.log(`loadIndex: ${loadIndex}`);
    console.log(`All pictures length: ${pictures.length}`)
    console.log(`Number of visible pictures: ${visiblePictures.length}`);
 
    useEffect(() => {
        // Initial load
    console.log("initial load");
    loadMoreImages(true);
    }, [pictures]);

    const loadMoreImages = (fresh) => {
        console.log(`loadMoreImages: ${fresh}`);
        if (fresh) {
            setVisiblePictures(pictures.slice(0, 20));
            setLoadIndex(20);
        } else {
            const moreImages = pictures.slice(loadIndex, loadIndex + 20); // Load next 20 images
            setVisiblePictures([...visiblePictures, ...moreImages]);
            setLoadIndex(loadIndex + 20);
        }
      };

    useEffect(() => {
    console.log("init scroll listener");
    const handleScroll = () => {
        if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight) return;
        loadMoreImages(false);
    };

    // Attach scroll event listener
    window.addEventListener('scroll', handleScroll);

    return () => {
        // Clean up
        window.removeEventListener('scroll', handleScroll);
        console.log("cleaned up");
    };
    }, [visiblePictures, loadIndex]);


    console.log("picture grid render");


    const fullScreenIcon = (icon, callback, disabled) => {
        return (
        <IconButton disabled={disabled} onClick={callback}>{icon}</IconButton>
    )};


    const currentImage = visiblePictures[index];
    const nextIndex = (index + 1) % visiblePictures.length;
    const nextImage = visiblePictures[nextIndex] || currentImage;
    const prevIndex = (index + visiblePictures.length - 1) % visiblePictures.length;
    const prevImage = visiblePictures[prevIndex] || currentImage;

    const handleClick = (index, item) => setIndex(index);
    const handleClose = () => setIndex(-1);
    const handleMovePrev = () => setIndex(prevIndex);
    const handleMoveNext = () => setIndex(nextIndex);

    return (
      <>
        <Gallery
          images={visiblePictures}
          onClick={handleClick}
          rowHeight={300}
          enableImageSelection
        />
        {/* {!!currentImage && ( */}
        {/* // <Lightbox
            //     mainSrc={currentImage.storageURL}
            //     imageTitle={currentImage.caption}
            //     mainSrcThumbnail={currentImage.storageURL}
            //     nextSrc={nextImage.storageURL}
            //     nextSrcThumbnail={nextImage.storageURL}
            //     prevSrc={prevImage.storageURL}
            //     prevSrcThumbnail={prevImage.storageURL}
            //     onCloseRequest={handleClose}
            //     onMovePrevRequest={handleMovePrev}
            //     onMoveNextRequest={handleMoveNext}
            //     toolbarButtons={[
            //         // fullScreenIcon(<RotateLeftIcon sx={{ color: "white"}} disabled/>, rotateLeft),
            //         // fullScreenIcon(<RotateRightIcon  sx={{ color: "white"}} disabled/>, rotateRight),
            //         // fullScreenIcon(<DownloadIcon  sx={{ color: "white"}}/>, handleDownload),
            //     ]}
            // />
            // )} */}
      </>
    )
}

export default PictureGrid;