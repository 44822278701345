import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import TopNavHome from '../components/header/TopNavHome';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  margin-top: 65px; // Adjust this value based on the actual height of TopNavHome
`

const PageWrapper = ({ title, children }) => (
  <Fragment>
    <Helmet>
      <title>{title}</title>
    </Helmet>
    <Wrapper>
      <TopNavHome />
      <Content>{children}</Content>
    </Wrapper>
  </Fragment>
);

export default PageWrapper;
