import React, { useEffect } from "react";
import TopNavHome from "../components/header/TopNavHome";
import { Paper, Grid, Typography } from "@mui/material";


// function that returns the time left until the event in days, hours, minutes, seconds
const getTimeLeft = (date) => {
    const difference = date - new Date();
    let timeLeft = {};

    if (difference > 0) {
        timeLeft = {
            days: Math.floor(difference / (1000 * 60 * 60 * 24)),
            hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((difference / 1000 / 60) % 60),
            seconds: Math.floor((difference / 1000) % 60),
        };
    }

    return timeLeft;
}

const dadRetirement = new Date('2024-09-01T00:00:00');
const momRetirement = new Date('2027-04-11T00:00:00');

const Countdown = () => {
    const [timeLeft, setTimeLeft] = React.useState(getTimeLeft(dadRetirement));

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(getTimeLeft(dadRetirement));
        }, 1000);

        // Clear timeout if the component is unmounted
        return () => clearTimeout(timer);
    }, [timeLeft]);

    const countdownOver = !timeLeft.days && !timeLeft.hours && !timeLeft.minutes && !timeLeft.seconds;

    const counterView = (number, label) => {
        return (
            <Grid container  xs={6} md={2} direction={"column"} justifyContent="center" sx={{ width: "100%"}}>
                {numberGridItem(number)}
                {labelGridItem(label)}
            </Grid>
        );
    }

    const numberGridItem = (number) => {
        return (
            <Grid item containerjustifyContent="center" alignItems="center">
                <Typography variant="h2" align="center">
                    {number}
                </Typography>
            </Grid>
        );
    };

    const labelGridItem = (label) => {
        return (
            <Grid item container justifyContent="center"  alignItems="center">
                <Typography variant="h6" align="center">
                    {label}
                </Typography>
            </Grid>
        );
    };

    const countdownOverMessage = () => {
        return (
            <Grid container direction={"column"} justifyContent="center" sx={{ width: "100%"}}>
                <Grid item container xs={12} justifyContent="center">
                    <Typography variant="h2" align="center" gutterBottom>
                        Countdown Over!
                    </Typography>
                </Grid>
                <Grid item container xs={12} justifyContent="center">
                    <Typography variant="h6" align="center">
                        Dad has retired!
                    </Typography>
                </Grid>
            </Grid>
        );
    }

    return (
        <div style={{ 
            maxWidth: 900, 
            marginLeft: 'auto', 
            marginRight: 'auto'
        }} className="main-page-wrapper">
            
            <TopNavHome /> 
            <br />
            <br />
            <br />
            {!countdownOver && <Paper elevation={3} style={{ padding: 20, margin: 10, background: 'radial-gradient(circle, #FFCC00 0%, #FF6600 100%)'}}>
                
                <Typography variant="h2" align="center" gutterBottom>
                    Dad's Retirement Countdown
                </Typography>
                <Grid container direction={"row"} justifyContent="center" sx={{ width: "100%"}}>
                    {counterView(timeLeft.days, `Day${timeLeft.days > 1 ? 's' : ''}`)}
                    {counterView(timeLeft.hours, `Hour${timeLeft.hours > 1 ? 's' : ''}`)}
                    {counterView(timeLeft.minutes, `Minute${timeLeft.minutes > 1 ? 's' : ''}`)}
                    {counterView(timeLeft.seconds, `Second${timeLeft.seconds > 1 ? 's' : ''}`)}
                </Grid>
            </Paper>}
            {countdownOver && countdownOverMessage()}
            
        </div>
        
        
    );
};

export default Countdown;
