import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Button, Tooltip, FormControl, FormLabel, Paper, Card, CardContent } from '@mui/material';
import { Rating } from '@mui/material';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import { styled } from '@mui/material/styles';

import AOS from 'aos';
import 'aos/dist/aos.css';
import TopNavHome from '../../components/header/TopNavHome';
import {gad7Questions as questions, anxietyScoring  } from "./gad7";

const StyledRating = styled(Rating)(({ theme }) => ({
  '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
    color: theme.palette.action.disabled,
  },
}));

// Updated customIcons to be an array
const customIcons = [
  {
    icon: <SentimentVerySatisfiedIcon color="success" />,
    label: 'Nearly every day',
  },
  {
    icon: <SentimentSatisfiedAltIcon color="warning" />,
    label: 'More than half the days',
  },
  {
    icon: <SentimentSatisfiedIcon color="warning" />,
    label: 'Several days',
  },
  {
    icon: <SentimentDissatisfiedIcon color="error" />,
    label: 'Not at all',
  },
];

function IconContainer(props) {
  const { value, ...other } = props;
  if (customIcons[value - 1]) {
    return (
      <Tooltip title={customIcons[value - 1].label} variant={"body2"}>
        <span {...other}>{customIcons[value - 1].icon}</span>
      </Tooltip>);
  } else {
    return (<></>);
  }
}

IconContainer.propTypes = {
  value: PropTypes.number.isRequired,
};

const labels = {
  1: 'Not at all',
  2: 'Several days',
  3: 'More than half the days',
  4: 'Nearly every day'
}; 

const Survey = () => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [responses, setResponses] = useState(new Array(questions.length).fill(0));
  const [score, setScore] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [resultMessage, setResultMessage] = useState('');
  const [hover, setHover] = useState(-1);

  const [showResults, setShowResults] = useState(false);  

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const handlePrevious = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const showResult = (finalScore) => {
    const result = getAnxietyResult(finalScore);
    setResultMessage(`Score ${finalScore}: ${result}`);
    setShowResults(true); // Show the results card
  };

  const handleNextOrSubmit = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      // Calculate final score and show result
      const finalScore = responses.reduce((acc, val) => acc + val, 0);
      showResult(finalScore);
    }
  };

  const handleRatingChange = (newValue) => {
    setResponses(prev => {
      const newResponses = [...prev];
      newResponses[currentQuestionIndex] = newValue;
      return newResponses;
    });
  };

  const getAnxietyResult = (score) => {
    const result = anxietyScoring.find(range => score >= range.min && score <= range.max);
    return result ? result.result : 'Unknown';
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const renderQuestion = (question) => {
    return (
      <FormControl component="fieldset">
        <FormLabel component="legend">{question.text}</FormLabel>
        <Box sx={{ display: 'flex', alignItems: 'center', padding: 2 }}>
          <StyledRating
            name={`question_${question.id}`}
            value={responses[currentQuestionIndex]}
            precision={1}
            IconContainerComponent={IconContainer}
            getLabelText={(value) => customIcons[value] ? customIcons[value].label : ""}
            highlightSelectedOnly
            onChange={(event, newValue) => {
              handleRatingChange(newValue);
            }}
            onChangeActive={(event, newHover) => {
              setHover(newHover);
            }}
          />
          <Box sx={{ ml: 2 }}>{labels[hover !== -1 ? hover : 0]}</Box>
        </Box>
      </FormControl>
    );
  };

  return (
    <div className="main-page-wrapper">
      <TopNavHome/>
      <div className="hero-banner-two">
        <br /> 
        <br />
        <Paper sx={{ width: '500px', height: '500px', margin: 'auto', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', padding: '20px' }}>
          <Typography variant="h4" sx={{ mb: 2 }}>
            Anxiety Screening
          </Typography>
          <Typography variant="h6" sx={{ mb: 2 }}>
            In the past week, how often have you felt the following:
          </Typography>
          <div style={{ flex: '1', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <Box key={questions[currentQuestionIndex].id} data-aos="fade-left">
              {renderQuestion(questions[currentQuestionIndex])}
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              {currentQuestionIndex > 0 && (
                <Button variant="outlined" onClick={handlePrevious}>
                  Previous
                </Button>
              )}
              <Button variant="contained" onClick={handleNextOrSubmit}>
                {currentQuestionIndex < questions.length - 1 ? 'Next' : 'Submit'}
              </Button>
            </Box>
          </div>
        </Paper>
        
        {showResults && (
          <Card sx={{ width: '500px', height: 'auto', margin: 'auto', marginTop: '20px' }}>
            <CardContent>
              <Typography variant="h5" component="div">
                Results
              </Typography>
              {/* <Typography variant="body2" color="text.secondary">
                
              </Typography> */}
              <Typography sx={{ mt: 2 }}>
                {/* Add any additional description or content here */}
                {resultMessage}
              </Typography>
            </CardContent>
          </Card>
        )}
      </div>
    </div>
  );
};

export default Survey;