import MobileMenu, { DymockFamilyMenu } from "./MobileMenu"
import ThemeMainMenu from "./ThemeMainMenu"
import { useSnackbar } from "notistack"
import { useNavigate } from "react-router-dom"
import { getAuth } from "firebase/auth"
import { signOut } from "firebase/auth"
import { useFirebaseAuthentication } from "../../firebase/firebase"

import React, { useState } from "react"
import {
  AppBar,
  Avatar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Box,
  Menu,
  MenuItem,
  Popover,
  Typography,
  Collapse,
} from "@mui/material"
import MenuIcon from "@mui/icons-material/Menu"
import { Link } from "react-router-dom"
import { useMediaQuery } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { ExpandLess, ExpandMore } from "@mui/icons-material"

const TopNavHome = () => {
  const user = useFirebaseAuthentication()
  const [navbar, setNavbar] = useState(false)
  const [drawerAnchorEl, setDrawerAnchorEl] = useState(null)
  const [avatarMenuAnchorEl, setAvatarMenuAnchorEl] = useState(null)
  const [dymockMenuAnchorEl, setDymockMenuAnchorEl] = React.useState(null)
  const [dymockMobileCollapse, setDymockMobileCollapse] = React.useState(false)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))

  const toggleMenu = () => {
    if (window.scrollY >= 68) {
      setNavbar(true)
    } else {
      setNavbar(false)
    }
  }
  window.addEventListener("scroll", toggleMenu)

  const toggleDrawer = (open) => {
    if (open) {
      setDrawerAnchorEl(true)
    } else {
      setDrawerAnchorEl(null)
    }
  }

  const handleAvatarMenuOpen = (event) => {
    setAvatarMenuAnchorEl(event.currentTarget)
  }

  const handleAvatarMenuClose = () => {
    setAvatarMenuAnchorEl(null)
  }

  const handleDymockMenuOpen = (event) => {
    console.log("handleDymockMenuOpen", event.currentTarget)
    setDymockMenuAnchorEl(event.currentTarget)
  }

  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()

  const handleAvatarToggle = (event) => {
    setAvatarMenuAnchorEl(event.currentTarget)
  }

  const handleDrawerMenuClose = () => {
    setDrawerAnchorEl(null)
  }

  const handleDrawerMenuOpen = (event) => {
    setDrawerAnchorEl(event.currentTarget)
  }

  const handleDymockMenuClose = () => {
    setDymockMenuAnchorEl(null)
  }

  const login = () => {
    navigate("/signIn")
  }

  const logout = (event) => {
    const auth = getAuth()
    signOut(auth)
      .then(() => {
        enqueueSnackbar("You have been logged out", {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        })
        localStorage.setItem("loggedIn", "FALSE")
        navigate("/signin")
      })
      .catch((error) => {
        // An error happened.
      })
  }

  const onLogout = () => {
    logout()
    enqueueSnackbar("You have been logged out", {
      variant: "success",
      anchorOrigin: {
        vertical: "top",
        horizontal: "right",
      },
    })
    navigate("/")
  }

  let avatar = (
    <Avatar
      alt="login"
      onClick={handleAvatarToggle}
      sx={{ cursor: "pointer" }}
    />
  )

  let dymockMenu = (
    <Popover
      anchorEl={dymockMenuAnchorEl}
      open={Boolean(dymockMenuAnchorEl)}
      onClose={handleDymockMenuClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      disableRestoreFocus
    >
      <List sx={{ width: 200 }}>
        {DymockFamilyMenu.map((val, i) => (
          <ListItem
            button
            component={Link}
            to={val.routerPath}
            key={i}
            sx={{ pl: 4 }}
            onClick={() => setAvatarMenuAnchorEl(null)}
          >
            <ListItemText primary={val.name} />
          </ListItem>
        ))}
      </List>
    </Popover>
  )

  let avatarMenu = (
    <Popover
      anchorEl={avatarMenuAnchorEl}
      open={Boolean(avatarMenuAnchorEl)}
      onClose={handleAvatarMenuClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      disableRestoreFocus
    >
      <List sx={{ width: 200 }}>
        {user && (
          <ListItem onClick={onLogout}>
            <ListItemText primary="Sign Out" />
          </ListItem>
        )}
        {!user && (
          <ListItem onClick={login}>
            <ListItemText primary="Sign In" />
          </ListItem>
        )}
      </List>
    </Popover>
  )

  if (user) {
    const initial = user.displayName ? user.displayName.charAt(0) : "U"
    avatar = (
      <Avatar
        alt={user.displayName}
        src={user.avatar || user.photoURL}
        onClick={handleAvatarToggle}
        sx={{ cursor: "pointer" }}
      >
        {initial}
      </Avatar>
    )
  }

  return (
    <AppBar position="fixed">
      <Toolbar>
        <Box sx={{ flexGrow: 1 }}>
          <Link to="/">
            <img
              src="/images/logo/white_text_with_logo.png"
              alt="Logo"
              width={130}
            />
          </Link>
        </Box>

        {isMobile ? (
          <IconButton
            edge="end"
            color="inherit"
            aria-label="menu"
            onClick={() => toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
        ) : (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              variant="h6"
              component={Link}
              to="/"
              sx={{ marginRight: 2, textDecoration: "none" }}
            >
              Home
            </Typography>
            <Typography
              variant="h6"
              onClick={handleDymockMenuOpen}
              component={Link}
              sx={{ marginRight: 2, textDecoration: "none" }}
            >
              Dymock Family
            </Typography>
            {dymockMenu}
            {avatar}
            {avatarMenu}
            <Menu
              anchorEl={drawerAnchorEl}
              open={Boolean(drawerAnchorEl)}
              onClick={handleDrawerMenuOpen}
              onClose={handleDrawerMenuClose}
              onMouseEnter={handleDrawerMenuOpen}
              onMouseLeave={handleDrawerMenuClose}
            >
              {user ? (
                <MenuItem onClick={onLogout}>Sign Out</MenuItem>
              ) : (
                <MenuItem onClick={login}>Sign In</MenuItem>
              )}
            </Menu>
          </Box>
        )}

        <Drawer
          anchor="left"
          open={Boolean(drawerAnchorEl)}
          onClose={() => setDrawerAnchorEl(null)}
        >
          <Box sx={{ width: 250 }}>
            <List>
              <ListItem component={Link} to="/">
                <ListItemText primary="Home" />
              </ListItem>

              <ListItem>
                <ListItemText
                  primary="Dymock Family"
                  color="white"
                  component={Link}
                  onClick={() => setDymockMobileCollapse(!dymockMobileCollapse)}
                  sx={{ cursor: "pointer" }}
                />
                {dymockMobileCollapse ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={dymockMobileCollapse} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {DymockFamilyMenu.map((val, i) => (
                    <ListItem
                      button
                      component={Link}
                      to={val.routerPath}
                      key={i}
                      sx={{ pl: 4 }}
                    >
                      <ListItemText primary={val.name} />
                    </ListItem>
                  ))}
                </List>
              </Collapse>

              {/* Conditional Sign In / Sign Out */}
              {user ? (
                <ListItem button onClick={onLogout}>
                  <ListItemText primary="Sign Out" />
                </ListItem>
              ) : (
                <ListItem button component={Link} to="/signin">
                  <ListItemText primary="Sign In" />
                </ListItem>
              )}
            </List>
          </Box>
        </Drawer>
      </Toolbar>
    </AppBar>

    // <Fragment>
    //   <header
    //     style={{ backgroundColor: "green" }}
    //     className={
    //       navbar
    //         ? "theme-main-menu sticky-menu theme-menu-two fixed"
    //         : "theme-main-menu sticky-menu theme-menu-two"
    //     }
    //   >
    //     <div className="inner-content">
    //       <div className="container d-flex align-items-center justify-content-between">
    //         <div className="logo order-lg-0">
    //           <Link to="/" className="d-block">
    //             <img src="images/logo/logo_01.png" alt="" width={130} />
    //           </Link>
    //         </div>
    //         <nav className="navbar navbar-expand-lg order-lg-2">
    //           <div className="collapse navbar-collapse" id="navbarNav">
    //             <ThemeMainMenu user={user} />
    //             <div className="mobile-content d-block d-lg-none">
    //               <div className="d-flex flex-column align-items-center justify-content-center mt-70">
    //                 <a href="signin" className="get-in-touch-btn"></a>
    //               </div>
    //             </div>
    //           </div>
    //         </nav>
    //       </div>

    //       <MobileMenu user={user} />
    //     </div>
    //   </header>
    // </Fragment>
  )
}

export default TopNavHome
