import TopNavHome from "../../components/header/TopNavHome"

const SiteWrapper = ({ children }) => {
  return (
    <div
      style={{ maxWidth: 1500, marginLeft: "auto", marginRight: "auto" }}
      className="main-page-wrapper"
    >
      <TopNavHome />
      <br />
      <br />
      <main>{children}</main>
    </div>
  )
}

export default SiteWrapper
