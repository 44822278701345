import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField'; // Import the TextField component
import { deleteDoc, doc } from 'firebase/firestore';
import { db } from '../../../firebase/firebase';

function DeleteConfirmationDialog({ open, onClose, choreId }) {
  const [password, setPassword] = useState('');
  const [incorrectPassword, setIncorrectPassword] = useState(false);

  const onDelete = () => {
    // Check the entered password here
    if (password === 'flamingo') {
      handleDelete();
      onClose();
    } else {
      // Password is incorrect, display an error message
      setIncorrectPassword(true);
    }
  };

  const handleDelete = async () => {
    console.log(`Deleting chore ${choreId}`);
    const choreToDeleteRef = doc(db, 'chores', choreId);
    await deleteDoc(choreToDeleteRef);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    setIncorrectPassword(false); // Reset the incorrect password message
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Enter the password to delete this item
        </DialogContentText>
        {/* Use TextField for the password input */}
        <TextField
          label="Password"
          type="password"
          fullWidth
          value={password}
          onChange={handlePasswordChange}
          error={incorrectPassword}
          helperText={incorrectPassword ? 'Incorrect password. Please try again.' : ''}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={onDelete} color="error" autoFocus>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteConfirmationDialog;
