import React from 'react';
import {Helmet} from 'react-helmet';
import {Link} from 'react-router-dom';
import TopNavHome from '../../components/header/TopNavHome';
import CopyRightFour from '../../components/footer/CopyRightFour';
import HomeHeroBanner from '../../components/hero-banner/HomeHeroBanner';

const HomePage = () => {

    return (
        <div className="main-page-wrapper">
            <Helmet>
                <title>RyanDymock.com</title>
            </Helmet>
            <TopNavHome/> 
            <HomeHeroBanner/> 

            <div className="footer-style-two theme-basic-footer">
                <img src="images/shape/shape_16.svg" alt="" className="shapes shape-one"/>
                <img src="images/shape/shape_17.svg" alt="" className="shapes shape-two"/>
                <div className="container">
                  <div className="inner-wrapper">
                        {/* <FooterHome /> */}
                        <div className="bottom-footer">
                            <CopyRightFour />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomePage