import React, {Fragment, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom'
import { useFirebaseAuthentication } from '../../firebase/firebase';
import { logout } from '../../firebase/firebase';
import { useSnackbar } from 'notistack';
import {
    ProSidebar,
    SidebarHeader,
    SidebarFooter,
    SidebarContent,
    Menu,
    MenuItem,
    SubMenu
} from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';

const currentDate = new Date();
  currentDate.setMonth(currentDate.getMonth() - 1);

  const year = String(currentDate.getFullYear());
  const month = currentDate.toLocaleString('default', { month: 'long' });


const currentNewsLetterPath = `/newsletter/${year}/${month}`;

const HomeMenu =[
  {
    name: 'User Analysis',
    routerPath: '/'
  },
  {
    name: 'Artificial Intelligence',
    routerPath: '/artificial-intelligence'
  },
  {
    name: 'Data Science',
    routerPath: '/data-science'
  },
  {
    name: 'ChatBoot',
    routerPath: '/chatboot'
  },
  {
    name: 'Machine Learning',
    routerPath: '/machine-learning'
  },
];

const AboutMenu =[
  {
    name: 'About Us One',
    routerPath: '/about-one'
  },
  {
    name: 'About Us Two',
    routerPath: '/about-two'
  }
];

const ServiceMenu =[
  {
    name: 'Service One',
    routerPath: '/service-one'
  },
  {
    name: 'Service Two',
    routerPath: '/service-two'
  },
  {
    name: 'Service Details',
    routerPath: '/service-details'
  }
];

const TeamMenu =[
  {
    name: 'Team Member',
    routerPath: '/team-member'
  },
  {
    name: 'Team Details',
    routerPath: '/team-details'
  }
];


const PortfolioMenu =[
  {
    name: 'Portfolio 3 Column',
    routerPath: '/portfolio-3'
  },
  {
    name: 'Portfolio 2 Column',
    routerPath: '/portfolio-2'
  },
  {
    name: 'Portfolio Masonry',
    routerPath: '/portfolio-masonry'
  },
  {
    name: 'Single Portfolio',
    routerPath: '/portfolio-single'
  },

];


const BlogMenu =[
  {
    name: 'Grid Layout',
    routerPath: '/blog-grid'
  },
  {
    name: 'Grid With Sidebar',
    routerPath: '/blog-sidebar'
  },
  {
    name: 'Blog Masonry',
    routerPath: '/blog-masonry'
  },
  {
    name: 'Blog Standard',
    routerPath: '/blog-standard'
  },
  {
    name: 'Blog Details',
    routerPath: '/blog-Details'
  },

];

export const DymockFamilyMenu = [
  // {
  //     name: 'Picture Sorting',
  //     routerPath: '/picture-sorting'
  // },
  // {
  //     name: 'Photo Journal',
  //     routerPath: '/photo-journal'
  // },
  {
    name: "Addresses",
    routerPath: "/addresses",
  },
  {
    name: "Bedtime Stories",
    routerPath: "/bedtime-stories",
  },
  // {
  //   name: "Come Follow Me",
  //   routerPath: "/come-follow-me",
  // },
  {
    name: "Chores",
    routerPath: "/chores",
  },
  {
    name: "Expenses",
    routerPath: "/expenses",
  },
  // {
  //   name: 'Podcast',
  //   routerPath: '/podcast'
  // },
  {
    name: "Growing Up Pictures",
    routerPath: "/viewPictures",
  },
  {
    name: "Dad Retirement",
    routerPath: "/retirement",
  },
  {
    name: "Sibling Newsletter",
    routerPath: currentNewsLetterPath,
  },
  // {
  //     name: 'Anxiety Screening',
  //     routerPath: '/leah'
  // },
  // {
  //     name: 'Bedtime Stories',
  //     routerPath: '/bedtime-stories'
  // }
]

const MobileMenu = () => {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const user = useFirebaseAuthentication();

    const onLogout = () => {
        logout();
        enqueueSnackbar('You have been logged out', {
            variant: 'success',
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
            },
        });
        navigate("/");
    }

    const [click, setClick] = useState(false);
   
    const handleClick = () => {
        setClick(!click);
    }
    return (
      <Fragment>
        <div className="mobile-menu-wrapper">
          <div className="moblie-menu-toggler">
            <button
              className={
                click
                  ? "navbar-toggler active d-block d-lg-none"
                  : "navbar-toggler d-block d-lg-none"
              }
              type="button"
              onClick={handleClick}
            >
              <span />
            </button>
          </div>
          <ProSidebar
            className={click ? "mobile-menu menu-open" : "mobile-menu"}
          >
            <SidebarHeader>
              <div className="mobile-logo">
                <Link to="/">
                  <img src="images/logo/logo_02.png" alt="" />
                </Link>
              </div>
              <div className="close-menu" onClick={handleClick}>
                <i className="bi bi-x-lg"></i>
              </div>
            </SidebarHeader>
            <SidebarContent>
              <Menu iconShape="square">
                <MenuItem>
                  {""}
                  <Link to="/">Home</Link>
                </MenuItem>
                <SubMenu title="Dymock Family">
                  {DymockFamilyMenu.map((val, i) => (
                    <MenuItem key={i}>
                      <Link to={val.routerPath}>{val.name}</Link>
                    </MenuItem>
                  ))}
                </SubMenu>
                {user && (
                  <MenuItem onClick={onLogout}>
                    {""}Sign Out
                    {/* <Link to="/">Sign Out</Link> */}
                  </MenuItem>
                )}
                {!user && (
                  <MenuItem>
                    {""}
                    <Link to="/signin">Sign In</Link>
                  </MenuItem>
                )}
              </Menu>
            </SidebarContent>

            <SidebarFooter></SidebarFooter>
          </ProSidebar>
        </div>
      </Fragment>
    )
}

export default MobileMenu