import React, { useState } from 'react';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit'; 
import MonitorIcon from '@mui/icons-material/Monitor';

const ChoresCard = ({
  id,
  title,
  description,
  onMarkDone,
  onEdit,
  isComplete,
  onDelete,
}) => {
  const [showActions, setShowActions] = useState(false)

  const cardStyle = {
    display: "flex",
    justifyContent: "space-between",
    margin: 3,
    padding: 5,
    paddingTop: 10,
    paddingBottom: 10,
    backgroundColor: isComplete ? "#e0e0e0" : "white", // Gray out the card if completed
    textDecoration: isComplete ? "line-through" : "none", // Line-through text if completed
  }

  const toggleActions = () => {
    if (!isComplete) {
      setShowActions(!showActions)
      console.log(id)
    }
  }

  return (
    <Card style={cardStyle} onClick={toggleActions}>
      <CardContent sx={{ padding: "2px!important", width: "100%" }}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography
              variant="body2"
              style={{ textDecoration: isComplete ? "line-through" : "none" }}
            >
              {description}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
      {!isComplete && showActions && (
        <CardActions>
          <Button size="small" onClick={() => onMarkDone(id)}>
            <CheckIcon />
          </Button>
          <Button size="small" onClick={() => onEdit(id)}>
            <EditIcon />
          </Button>
          <Button size="small" onClick={() => onDelete(id)}>
            <DeleteIcon />
          </Button>
        </CardActions>
      )}
    </Card>
  )
}

export default ChoresCard;
