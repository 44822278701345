import { useState, useEffect, React } from 'react';
import { useNavigate } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import { Divider } from '@mui/material';
import { db } from '../firebase/firebase';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { registerNewUser, isValidEmail } from '../firebase/FirebaseDB';
import { collection, getDocs, doc, getDoc, onSnapshot, query, orderBy } from "firebase/firestore";

import Swal from 'sweetalert2';

import { useSnackbar } from 'notistack';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://ryandymock.com/">
        RyanDymock.com
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useFamilyCode = () => {
  const [code, setCode] = useState({});
  useEffect(() => {
    let mounted = true;
    const getFamilyCode = async() => {
      let results = {};
      const docSnapshot = await getDoc(doc(db, "Admin", "code"));
      if (docSnapshot.exists() && mounted) {  
        setCode(docSnapshot.data()['familyCode']);
      }
    };
    getFamilyCode();
    return () => mounted = false;
  }, []);
  return code;
}

const theme = createTheme();

export default function SignUpPage() {
    
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const familyCode = useFamilyCode();

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const registration = {
        firstName: data.get('firstName'),
        lastName: data.get('lastName'),
        email: data.get('email'),
        password: data.get('password'),
        familyCode: data.get('familyCode'),
    }
    validateFieldsAndSubmit(registration);
  };

  const showErrorMessage = (message) => {
    enqueueSnackbar(message, {
        variant: 'error',
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
        }});
  }

  const validateFieldsAndSubmit = (registration) => {
    if (!isValidEmail(registration.email)) {
      showErrorMessage("Please enter a valid email address");
      return;
    }
    if (registration.password.length < 5) {
      showErrorMessage("Please enter a longer password");
      return;
    }
    if (registration.familyCode.length < 5) {
      showErrorMessage("Incorrect family code");
      return;
    }
    if (registration.firstName.length < 1 || registration.lastName.length < 1) {
      showErrorMessage("Please enter your first and last name");
      return;
    }
    if (registration.familyCode !== familyCode) {
      showErrorMessage("Incorrect family code");
      return;
    }
    submitRegistration(registration);
  }

  const successCallback = (data) => {
    Swal.fire({
      title: "Registration Successful",
      text: "Registration submitted succesfully!  Check your email for validation.",
      icon: "success",
      button: "OKAY",
    }).then(() =>{
        navigate("/signIn");      

    });
  }

  const failureCallback = (data) => {
    showErrorMessage(data);
  }

  const submitRegistration = (registration) => {
    registerNewUser(registration, successCallback, failureCallback);
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          
          SIGN UP
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="familyCode"
                  label="Family Code"
                  name="familyCode"
                  autoComplete="family-code"
                />
              </Grid>
              <Grid item xs={12}>
               <Divider  variant="middle"/>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="family-name"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign Up
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="/signIn" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
}