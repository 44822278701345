import React, { useState, useMemo } from "react"
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  flexRender,
} from "@tanstack/react-table"
import IconButton from "@mui/material/IconButton"
import EditIcon from "@mui/icons-material/Edit"
import DeleteIcon from "@mui/icons-material/Delete"
import DeleteDialog from "./DeleteDialog"
import ExpenseDialog from "./ExpenseDialog"
import { Button } from "@mui/material"

const ExpensesTable = ({ expenses, onEdit, onDelete }) => {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [expenseToDelete, setExpenseToDelete] = useState(null)
  const [editDialogOpen, setEditDialogOpen] = useState(false)
  const [expenseToEdit, setExpenseToEdit] = useState(null)
  const [sorting, setSorting] = useState([{ id: "date", desc: true }])

  const isMobile = window.innerWidth < 768

  const columns = useMemo(
    () => [
      {
        accessorKey: "date",
        header: "Date",
        sortingFn: "datetime",
        cell: (info) => info.getValue(),
        sortDescFirst: true,
      },
      {
        accessorKey: "description",
        header: "Description",
      },
      {
        accessorKey: "amount",
        header: "Amount",
        cell: ({ row }) => `$${Number(row.original.amount).toFixed(2)}`,
      },
      {
        accessorKey: "category",
        header: "Category",
      },
      {
        accessorKey: "actions",
        header: "Actions",
        cell: ({ row }) => (
          <div>
            <IconButton onClick={() => handleEditClick(row.original)}>
              <EditIcon />
            </IconButton>
            <IconButton onClick={() => handleDeleteClick(row.original)}>
              <DeleteIcon />
            </IconButton>
          </div>
        ),
      },
    ],
    []
  )

  const table = useReactTable({
    data: expenses,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })

  const handleDeleteClick = (expense) => {
    setExpenseToDelete(expense)
    setDeleteDialogOpen(true)
  }

  const handleEditClick = (expense) => {
    setExpenseToEdit(expense)
    setEditDialogOpen(true)
  }

  const confirmDelete = () => {
    onDelete(expenseToDelete.id)
    setDeleteDialogOpen(false)
    setExpenseToDelete(null)
  }

  const closeDeleteDialog = () => {
    setDeleteDialogOpen(false)
    setExpenseToDelete(null)
  }

  const handleAddExpenseClick = () => {
    setExpenseToEdit(null) // Clear edit state when adding a new expense
    setEditDialogOpen(true)
  }

  const handleSaveExpense = (expense) => {
    if (expenseToEdit) {
      onEdit(expense)
    } else {
      onEdit(expense) // If adding a new expense, generate a new ID
    }
    setEditDialogOpen(false)
  }

  return (
    <div
      style={{
        width: "100%",
        overflowX: "auto",
        margin: "auto",
      }}
    >
      <Button onClick={handleAddExpenseClick} variant="contained">
        Add Expense
      </Button>
      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  style={{
                    borderBottom: "1px solid black",
                    padding: "10px",
                    textAlign: "left",
                    fontSize: isMobile ? "12px" : "20px",
                    cursor: header.column.getCanSort() ? "pointer" : "default",
                  }}
                  onClick={header.column.getToggleSortingHandler()}
                >
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                  {{
                    asc: " 🔼",
                    desc: " 🔽",
                  }[header.column.getIsSorted()] ?? null}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  style={{
                    borderBottom: "1px solid black",
                    padding: "10px",
                    fontSize: isMobile ? "12px" : "20px",
                  }}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <DeleteDialog
        open={deleteDialogOpen}
        onClose={closeDeleteDialog}
        onConfirm={confirmDelete}
        expense={expenseToDelete}
      />
      <ExpenseDialog
        open={editDialogOpen}
        onClose={() => setEditDialogOpen(false)}
        onSave={handleSaveExpense}
        expense={expenseToEdit}
      />
    </div>
  )
}

export default ExpensesTable
