import React, { useState, useImperativeHandle, forwardRef } from "react"
import Confetti from "react-confetti"

const ConfettiTrigger = forwardRef(({ children }, ref) => {
  const [confettiConfig, setConfettiConfig] = useState(null)

  // Trigger the confetti animation in the center of the viewport
  const handleTrigger = () => {
    const x = window.innerWidth / 2
    const y = window.innerHeight / 2

    console.log("Trigger confetti at screen center:", { x, y })

    setConfettiConfig({
      x,
      y,
      duration: 3000, // Duration in milliseconds
    })

    // Clear the confetti after the duration
    setTimeout(() => setConfettiConfig(null), 3000)
  }

  // Expose triggerConfetti to the parent component
  useImperativeHandle(ref, () => ({
    triggerConfetti() {
      handleTrigger()
    },
  }))

  return (
    <div>
      {children}

      {confettiConfig && (
        <Confetti
          width={window.innerWidth}
          height={window.innerHeight}
          numberOfPieces={300}
          recycle={false}
          confettiSource={{
            x: confettiConfig.x,
            y: confettiConfig.y,
          }}
          style={{ position: "fixed", top: 0, left: 0, zIndex: 9999 }}
        />
      )}
    </div>
  )
})

export default ConfettiTrigger
