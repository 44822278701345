import { getFirestore, doc, setDoc, getDoc, collection, query, where, getDocs, addDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { db, functions, storage, auth } from "../../../firebase/firebase";
import { simulatedStory } from "./storySimulator";

// Get the currently logged-in user's ID
const getCurrentUserId = () => {
  const user = auth.currentUser;
  return user ? user.uid : null;
};

export const simulateStoryGeneration = (chapterIndex) => {
  return simulatedStory[chapterIndex];
};

// Function to save a new story to Firestore
export const saveStory = async (familyId, storyContent) => {
    const userId = getCurrentUserId();
    if (!userId) {
      throw new Error("User is not logged in");
    }
  
    try {
      const storyDocRef = await addDoc(collection(db, "stories"), {
        familyId: familyId,
        storyContent: storyContent,
        createdAt: new Date()
      });
      return storyDocRef.id;
    } catch (error) {
      console.error("Error saving story: ", error);
      throw error;
    }
  };
  
  // Function to fetch the latest story for the logged-in user's family
  export const getLatestStory = async (familyId) => {
    const userId = getCurrentUserId();
    if (!userId) {
      throw new Error("User is not logged in");
    }
  
    try {
      const q = query(collection(db, "stories"), where("familyId", "==", familyId));
      const querySnapshot = await getDocs(q);
      const stories = [];
      querySnapshot.forEach((doc) => {
        stories.push({ id: doc.id, ...doc.data() });
      });
      return stories.length ? stories[stories.length - 1] : null;
    } catch (error) {
      console.error("Error fetching stories: ", error);
      throw error;
    }
  };

// Function to call the LLM story generation Cloud Function
export const generateStory = async (inputData) => {
  try {
    const generateStoryFunction = httpsCallable(functions, "generateStory");
    const result = await generateStoryFunction(inputData);
    return result.data;
  } catch (error) {
    console.error("Error generating story: ", error);
    throw error;
  }
};

// Function to fetch chores for the logged-in user
export const getUserChores = async () => {
  const userId = getCurrentUserId();
  if (!userId) {
    throw new Error("User is not logged in");
  }

  try {
    const q = query(collection(db, "chores"), where("userId", "==", userId));
    const querySnapshot = await getDocs(q);
    const chores = [];
    querySnapshot.forEach((doc) => {
      chores.push({ id: doc.id, ...doc.data() });
    });
    return chores;
  } catch (error) {
    console.error("Error fetching chores: ", error);
    throw error;
  }
};

// Function to update a chore's completion status
export const updateChoreStatus = async (choreId, status) => {
  try {
    const choreDocRef = doc(db, "chores", choreId);
    await setDoc(choreDocRef, { status: status }, { merge: true });
  } catch (error) {
    console.error("Error updating chore status: ", error);
    throw error;
  }
};


// Function to upload example story data with text and choices
export const uploadExampleStoryData = async (familyId) => {
    const exampleStories = [
      {
        familyId: familyId,
        chapterTitle: "Chapter 1: The Journey Begins",
        storyContent: "The family gathered their belongings and set off on an adventure of a lifetime...",
        choices: [
          { text: "Take the path through the forest", nextChapterId: "chapter2_forest" },
          { text: "Climb the mountain", nextChapterId: "chapter2_mountain" }
        ],
        createdAt: new Date(),
      },
      {
        familyId: familyId,
        chapterTitle: "Chapter 2: The First Challenge",
        storyContent: "After a long journey, the family arrived at a crossroad...",
        choices: [
          { text: "Build a bridge to cross the river", nextChapterId: "chapter3_bridge" },
          { text: "Find another way around", nextChapterId: "chapter3_detour" }
        ],
        createdAt: new Date(),
      },
      {
        familyId: familyId,
        chapterTitle: "Chapter 3: The Quest for the Golden Key",
        storyContent: "The family gathered around the mysterious map that had appeared on their kitchen table...",
        choices: [
          { text: "Climb the mountain", nextChapterId: "chapter4_mountain" },
          { text: "Explore the valley", nextChapterId: "chapter4_valley" }
        ],
        createdAt: new Date(),
      },
    ];
  
    try {
      for (const story of exampleStories) {
        await addDoc(collection(db, "stories"), story);
      }
      console.log("Example stories uploaded successfully!");
    } catch (error) {
      console.error("Error uploading example stories: ", error);
    }
  };