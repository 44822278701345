import { differenceInCalendarDays } from "date-fns";

const DAYS_IN_WEEK = 7;
const DAYS_IN_MONTH = 30;

export const isChoreComplete = (chore) => {
    if (!chore.last || !chore.frequency) {
        return false;
    }

    const today = new Date();
    const lastDate = chore.last.toDate();
    const daysSinceLastCompleted = differenceInCalendarDays(today, lastDate);
    const frequency = chore.frequency.toLowerCase();

    switch (frequency) {
        case "daily":
            return daysSinceLastCompleted === 0;
        case "weekly":
            return daysSinceLastCompleted <= DAYS_IN_WEEK;
        case "monthly":
            return daysSinceLastCompleted <= DAYS_IN_MONTH;
        default:
            return false;
    }
};
